/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, Suspense, useRef } from "react";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { OrbitControls, Environment } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Male } from "../components/3D/male";
import { Female } from "../components/3D/female";

import Button from "components/UI/Button";
import { sections } from "../../CONSTANTS";
import { ReactComponent as MaleIcon } from "../../../../assets/ic_male_plain.svg";
import { ReactComponent as FemaleIcon } from "../../../../assets/ic_female_plain.svg";
import { ReactComponent as WidthIcon } from "../../../../assets/project/ic_width.svg";
import { ReactComponent as HeightIcon } from "../../../../assets/project/ic_height.svg";
import { ReactComponent as DrawIcon } from "../../../../assets/ico_draw.svg";
import { ReactComponent as ChangeIcon } from "../../../../assets/ic_remove.svg";
// import { ReactComponent as ResetIcon } from "../../../../assets/ic_reset.svg";
import { ReactComponent as AroundIcon } from "../../../../assets/ic_rotate.svg";

import Select from "react-select";
import { SERVER_URL } from "utils/constants";
import "../../styles/3d.css";
import html2canvas from "html2canvas";
import PolyDrawKonva from "./PolyDrawPen";

const placeYourTattoValidationSchema = yup.object({
  height: yup
    .number()
    .integer()
    .typeError("Height must be a number")
    .min(1)
    .max(1000)
    .required(),
  width: yup
    .number()
    .integer()
    .typeError("Width must be a number")
    .min(1)
    .max(1000)
    .required(),
});

export default function PlaceYourTattoPage(props) {
  const [selectedGender, setSelectedGender] = useState("female");
  const [drawMode, setDrawMode] = useState(false);
  const [resetDrawMode, setResetDrawMode] = useState(
    props?.storedProjectState?.placeTatooForm?.get("modelPictureWithPoints")
      ? true
      : false
  );
  const [mySkinOptions, setMySkinOptions] = useState([]);
  const [selectedSkinOption, setSelectedSkinOption] = useState(null);
  const [o, setO] = useState(0);
  const [modelPicture, setModelPicture] = useState(
    props?.storedProjectState?.placeTatooForm?.get("modelPictureWithPoints")
  );

  const divRef = useRef(null);
  const [drawUndo, setDrawUndo] = useState(false);
  const [drawRedo, setDrawRedo] = useState(false);
  const [drawReset, setDrawReset] = useState(false);
  const [hideUnRedo, seHideUnRedo] = useState(true);

  const [dimensions, setDimensions] = useState(
    props?.storedProjectState?.placeTatooForm?.get("dimensions")
      ? JSON.parse(props?.storedProjectState?.placeTatooForm?.get("dimensions"))
      : {
          width: 0,
          height: 0,
        }
  );
  const [selectedIsAround, setSelectedIsAround] = useState(
    props?.storedProjectState?.placeTatooForm?.get("isTatooAround")
      ? String(
          props?.storedProjectState?.placeTatooForm?.get("isTatooAround")
        ).toLowerCase() === "true"
      : false
  );

  let height = "";
  let width = "";
  if (props?.storedProjectState?.placeTatooForm) {
    height = props?.storedProjectState?.placeTatooForm?.get("height");
    width = props?.storedProjectState?.placeTatooForm?.get("width");
  }

  useEffect(() => {
    if (props?.storedProjectState?.placeTatooForm) {
      setSelectedGender(
        props?.storedProjectState?.placeTatooForm?.get("gender")
      );
    }
  }, [props?.storedProjectState?.placeTatooForm, mySkinOptions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let intialFormValues = {
    height: height,
    width: width,
  };

  const handleFormSubmit = async (values) => {
    if (!modelPicture) {
      return;
    }
    let obj = new FormData();
    obj.append("height", values.height);
    obj.append("width", values.width);
    obj.append("gender", selectedGender);
    obj.append("skinToneId", selectedSkinOption.value);
    seHideUnRedo(true);
    const canvas = await html2canvas(
      document.querySelector("#modelboardDraw"),
      { logging: false }
    );
    const image = canvas.toDataURL("image/png", 1.0);
    var file = await dataUrlToFile(image, "modelPicture-" + Date.now());
    obj.append("modelPicture", file);
    obj.append("modelPictureWithPoints", image);
    obj.append("modelPictureOriginal", modelPicture);
    obj.append("dimensions", JSON.stringify(dimensions));
    obj.append("isTatooAround", selectedIsAround);
    props.setStoredProjectState((storedProjectState) => ({
      ...storedProjectState,
      placeTatooForm: obj,
    }));
    props.onSubmit(obj, sections.Budget, "placeTatooForm");
  };

  async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  }

  const populateSkinTones = () => {
    let skinTones = props.skinToneObj;
    let skinTonesOptionItems = [];
    if (skinTones && skinTones.length > 0) {
      skinTones.forEach(function (value, index) {
        skinTonesOptionItems.push({
          label: value.name,
          value: value.id,
          text: "",
          icon: (
            <img
              src={SERVER_URL + value.imageUrl}
              alt="Skin Tone"
              className="h-8 object-scale-down"
            />
          ),
        });
        if (
          props?.storedProjectState?.placeTatooForm?.get("skinToneId") ==
          value.id
        ) {
          setO(index);
        }
      });
      setMySkinOptions(skinTonesOptionItems);
      setSelectedSkinOption(skinTonesOptionItems[o]);
    }
  };

  useEffect(() => {
    populateSkinTones();
  }, [props.skinToneObj]);

  const handleSkintoneChange = (e) => {
    setSelectedSkinOption(e);
  };

  useEffect(
    () => {
      setSelectedSkinOption(mySkinOptions[o]);
    },
    [mySkinOptions],
    o
  );

  const onClickDrawMode = async () => {
    if (!drawMode) {
      const canvas = await html2canvas(document.querySelector("#modelboard"));
      const image = canvas.toDataURL("image/png", 1.0);
      setModelPicture(image);
      seHideUnRedo(false);
    } else {
      setModelPicture(null);
      seHideUnRedo(true);
    }
    setDrawMode(!drawMode);
  };

  const onClickResetDrawMode = async () => {
    setModelPicture(
      props?.storedProjectState?.placeTatooForm?.get("modelPictureOriginal")
    );
    setDrawMode(true);
    seHideUnRedo(false);
    setResetDrawMode(false);
  };

  // const onClickReset3D = () => {
  //   console.log("onClickReset3D");
  // };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: intialFormValues,
    validationSchema: placeYourTattoValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setDimensions(divRef.current.getBoundingClientRect());
    }
  }, [divRef.current]);
  return (
    <section className="flex flex-1 py-0" aria-labelledby="gallery-heading">
      <div className="">
        <div className="text-sm text-primary font-sans hidden lg:block">
          Note: Use Shift Key + Mouse or Trackpad to move the 3d model up/down
          within the drawing box{" "}
        </div>
        <FormikProvider value={formik}>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col pb-4 grid grid-cols-8 mt-0 md:mt-2 lg:mt-0"
            encType="multipart/form-data"
          >
            {/* Top */}
            <div className="col-start-1 md:col-start-1 col-span-8 md:col-span-1 flex flex-row md:flex-col mt-2 md:mt-0">
              <div
                className={`h-16 xl:h-24 w-16 md:w-full flex items-center justify-items-center justify-center mb-1 mr-1 md:mr-0 aspect-square flex-col ${
                  selectedGender === "female"
                    ? "bg-primary text-white"
                    : "bg-grayBG text-primary"
                }`}
                onClick={() => setSelectedGender("female")}
              >
                <div className="text-xs pb-2">Female</div>

                <FemaleIcon
                  fill={selectedGender == "female" ? "#fff" : "#afafaf"}
                />
              </div>

              <div
                className={`h-16 xl:h-24 w-16 md:w-full flex items-center justify-items-center justify-center mb-1 mr-1 md:mr-0  flex-col ${
                  selectedGender === "male"
                    ? "bg-primary text-white"
                    : "bg-grayBG text-primary"
                }`}
                onClick={() => setSelectedGender("male")}
              >
                <div className="text-xs pb-2">Male</div>

                <MaleIcon
                  fill={selectedGender == "male" ? "#fff" : "#afafaf"}
                />
              </div>

              {!resetDrawMode && (
                <div
                  className={`h-16 xl:h-24 md:w-full w-16 flex items-center justify-items-center justify-center mb-1 mr-1 md:mr-0 flex-col ${
                    drawMode
                      ? "bg-primary text-white"
                      : "bg-grayBG text-primary"
                  }`}
                  onClick={() => onClickDrawMode()}
                >
                  <div className="text-xs pb-2">Draw</div>
                  <DrawIcon fill={drawMode ? "#fff" : "#afafaf"} />
                </div>
              )}

              {resetDrawMode && (
                <div
                  className={`h-16 xl:h-24 md:w-full w-16 flex items-center justify-items-center justify-center mb-1 mr-1 md:mr-0 bg-grayBG text-primary flex-col`}
                  onClick={() => onClickResetDrawMode()}
                >
                  <div className="text-xs">Reset</div>

                  <ChangeIcon fill={"#afafaf"} />
                </div>
              )}

              <div
                className={`h-16 xl:h-24 w-16 md:w-full flex items-center justify-items-center justify-center mb-1 mr-1 md:mr-0 flex-col ${
                  selectedIsAround
                    ? "bg-primary text-white"
                    : "bg-grayBG text-primary"
                }`}
                onClick={() => setSelectedIsAround(!selectedIsAround)}
              >
                <div className="text-xs pb-2">Around</div>
                <AroundIcon
                  stroke={selectedIsAround ? "#fff" : "#afafaf"}
                  height={"24"}
                />
              </div>
              <div className="h-16 xl:h-24 md:w-full w-16 text-base mb-0 md:mt-0 items-center justify-items-center justify-center flex flex-col bg-grayBG">
                <div className="text-xs text-primary mb-0 mt-2">Tone</div>

                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                  placeholder="Tone"
                  value={selectedSkinOption}
                  options={mySkinOptions}
                  onChange={handleSkintoneChange}
                  defaultValue={mySkinOptions[o]}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        aspectRatio: 1 / 1,
                      }}
                    >
                      {e.icon}
                    </div>
                  )}
                />
              </div>

              {/* <div
                  className={`h-16 xl:h-24 md:w-full w-16 flex items-center justify-items-center justify-center mt-0 md:mt-1 ml-1 md:ml-0 bg-grayBG text-primary`}
                  // onClick={() => onClickReset3D()}
                > */}
              {/* <ResetIcon fill={drawMode ? "#fff" : "#afafaf"} /> */}
              {/* </div> */}
            </div>
            <div
              className="col-start-1 col-span-8 md:col-start-2 md:col-span-7 border-primary border"
              ref={divRef}
            >
              {!modelPicture && (
                <Canvas
                  gl={{ preserveDrawingBuffer: true }}
                  camera={{ position: [2, 2, 5] }}
                  id="modelboard"
                >
                  <Suspense fallback={null}>
                    <OrbitControls
                      makeDefault
                      minPolarAngle={0}
                      maxPolarAngle={Math.PI / 1.75}
                    />
                    <Environment preset="warehouse" />
                    {selectedGender === "male" ? (
                      <Male scale={30.9} positiony={-2.4} />
                    ) : (
                      <Female scale={7} positiony={-2.4} />
                    )}
                  </Suspense>
                </Canvas>
              )}
              {modelPicture && (
                <div id="modelboardDraw" className="h-full w-full">
                  <div
                    data-html2canvas-ignore={true}
                    hidden={hideUnRedo}
                    className="absolute top-15 left-30 z-10 h-10 w-10"
                  >
                    <button
                      className="text-3xl w-10 border border-primary text-primary"
                      onClick={() => setDrawUndo(true)}
                      type="button"
                    >
                      &#8634;
                    </button>
                    <button
                      className="text-3xl w-10 border border-primary text-primary"
                      onClick={() => setDrawRedo(true)}
                      type="button"
                    >
                      &#8635;
                    </button>
                    <button
                      className="text-3xl w-10 border border-primary text-primary"
                      onClick={() => setDrawReset(true)}
                      type="button"
                    >
                      &#10005;
                    </button>
                  </div>
                  <PolyDrawKonva
                    modelPicture={modelPicture}
                    dimensions={dimensions}
                    undo={drawUndo}
                    setUndo={setDrawUndo}
                    redo={drawRedo}
                    setRedo={setDrawRedo}
                    reset={drawReset}
                    setReset={setDrawReset}
                    disableDraw={hideUnRedo}
                  />
                </div>
              )}
            </div>
            {/* Second */}
            {!drawMode && (
              <div
                className="col-start-1 md:col-start-2 lg:col-start-2 md:col-span-7 lg:col-span-7 col-span-12 mt-1 p-0 flex flex-row w-full mobile-customization"
                id="tweakpanecontainer"
              ></div>
            )}
            <div className="col-start-1 md:col-start-2 lg:col-start-2 col-span-8 lg:col-span-6 md:col-span-7 mt-5 p-0 flex flex-row">
              {/* Images */}
              {props?.uploadedImages?.map((file, i) => (
                <div className="w-12 h-12 border border-primary mr-2" key={i}>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={"Tatoo"}
                    className={"flex w-12 h-12 object-cover object-center"}
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-col items-center col-start-1 md:col-start-3 col-span-3 md:col-span-2 lg:col-start-3 lg:col-span-2 xl:col-start-4 xl:col-span-1 mt-5 justify-center">
              <div className="flex ">
                <div className="flex flex-1 w-4">
                  <WidthIcon fill="#C7A33F" />
                </div>
                <input
                  className="border-b-2 border-tertiary box-border w-full flex mx-4 md:mx-4 lg:mx-2 xl:mx-1 text-center text-sm focus:outline-none rounded-none"
                  type="number"
                  name="width"
                  id="width"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.width}
                  label="Width"
                  maxLength="4"
                  min={1}
                  // onInput={(e) => {
                  //   const inputElement = e.target;
                  //   if (!inputElement.validity.valid) {
                  //     formik.setFieldValue("width", 0);
                  //   } else {
                  //     formik.setFieldValue("width", inputElement.value);
                  //   }
                  // }}
                />
                <span className="flex text-xs font-sans">cm</span>
              </div>
              <p className="text-red text-xs mt-1">
                {formik.errors.width &&
                  formik.touched.width &&
                  formik.errors.width}
              </p>
            </div>
            {/* <div className="col-start-5 col-span-1" /> */}
            <div className="flex flex-col col-start-6 col-span-3 md:col-start-5 md:col-span-2 lg:col-start-6 lg:col-span-2 xl:col-start-6 xl:col-span-1 items-center mt-5 justify-center">
              <div className="flex flex-row">
                <div className="flex flex-1 w-4">
                  <HeightIcon fill="#C7A33F" />
                </div>
                <input
                  className="border-b-2 border-tertiary box-border w-full flex mx-4 md:mx-4 lg:mx-2 xl:mx-1 text-center text-sm focus:outline-none rounded-none"
                  type="number"
                  name="height"
                  id="height"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.height}
                  label="Height"
                  maxLength="4"
                  min={1}
                  // onInput={(e) => {
                  //   const inputElement = e.target;
                  //   if (!inputElement.validity.valid) {
                  //     formik.setFieldValue("height", 0);
                  //   } else {
                  //     formik.setFieldValue("height", inputElement.value);
                  //   }
                  // }}
                />
                <span className="flex text-xs font-sans">cm</span>
              </div>
              <p className="text-red text-xs mt-1 font-sans">
                {formik.errors.height &&
                  formik.touched.height &&
                  formik.errors.height}
              </p>
            </div>

            <div className="col-start-1 col-span-8  md:col-start-2 mt-1 mb-20">
              {!modelPicture &&
                formik.values.width > 0 &&
                formik.values.height > 0 && (
                  <p className="text-red text-xs mt-1 mb-2 font-sans">
                    {"Model Picture is required"}
                  </p>
                )}
              <div className="flex space-x-2 w-full md:px-10 xl:px-24 xl: mt-5">
                <Button
                  label="Back"
                  textsize="base"
                  onClick={() => props.onBack(sections.UploadPictures)}
                  background="white"
                  bordercolor="primary"
                  color="primary"
                />
                <Button
                  type="submit"
                  label="Next"
                  textsize="base"
                  bordercolor="primary"
                />
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
    </section>
  );
}

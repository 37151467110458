/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { defaultMapProps, MAPS_KEY } from "layouts/AdminConstants";

const ProjectsOnMap = (props) => {
  const [pointData, setPointData] = useState([]);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(defaultMapProps.zoom);
  const [center, setCenter] = useState(defaultMapProps.center);

  useEffect(() => {
    if (props?.projectList) {
      let points = [];
      props?.projectList?.map((items) => {
        points.push({
          id: items.id,
          title: items.id,
          lat: items?.latitude,
          lng: items?.longitude,
        });
      });
      setPointData(points);
    }
  }, [props?.projectList]);

  const mapContainerStyles = {
    height: "80vh",
    minWidth: "80%",
    flexGrow: 1,
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "flex-start",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAPS_KEY,
  });

  const onLoad = useCallback(
    function callback(map) {
      if (pointData.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        pointData.forEach((point) => {
          bounds.extend({ lat: point.lat, lng: point.lng });
        });
        map.fitBounds(bounds);
        setMap(map);
      }
    },
    [pointData]
  );

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMapDragEnd = (map) => {
    const newCenter = {
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng(),
    };

    setCenter(newCenter);
  };

  const handleZoomChanged = () => {
    if (map) {
      const newZoom = map.getZoom();
      setZoom(newZoom);
    }
  };

  return (
    <div style={containerStyle}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyles}
          center={center}
          zoom={zoom}
          options={{
            streetViewControl: false,
            gestureHandling: "greedy",
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragEnd={() => handleMapDragEnd(map)}
          onZoomChanged={handleZoomChanged}
        >
          {pointData?.map(({ lat, lng, id, title }) => {
            return (
              <Marker
                key={id}
                title={title.toString()}
                position={{
                  lat: lat,
                  lng: lng,
                }}
                draggable={true}
                label={id.toString()}
                onClick={() => props.projectOnClick(title)}
              />
            );
          })}
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProjectsOnMap;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { BOSS_USER_ID, BOSS_USER_NAME, SERVER_URL } from "utils/constants";
import { Modal } from "@mui/material";
import CustomButton from "components/UI/Button";
import FileViewer from "react-file-viewer";
import "../../styles/pdf.css";
import { useHistory } from "react-router-dom";

function RoomMessages({
  socket,
  setMsgCount,
  commentTicketId,
  projectId,
  loggedInUserId,
  isFileUploaded,
  selectedTabUserType,
  loggedInUserType,
}) {
  const [messages, setMessages] = useState({});
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState("");
  const [fileType, setFileType] = useState("");
  let history = useHistory();

  useEffect(() => {
    const messageListener = (message) => {
      if (message.projectId == projectId) {
        setMessages((prevMessages) => {
          const newMessages = { ...prevMessages };
          newMessages[message.id] = message;
          return newMessages;
        });
      }
    };

    socket.on("messageRc", messageListener);
    const reqObj = {
      projectId: projectId,
      fromUserId: loggedInUserId,
    };
    socket.emit("getMessagesRc", reqObj);
    return () => {
      socket.off("messageRc", messageListener);
    };
  }, [socket, projectId]);

  useEffect(() => {
    setMessages({});
    if (isFileUploaded && socket) {
      const reqObj = {
        projectId: projectId,
        fromUserId: loggedInUserId,
      };
      socket.emit("getMessagesRc", reqObj);
    }
  }, [isFileUploaded === true]);

  useEffect(() => {
    let data = [];
    data[commentTicketId] = Object.values(messages).length;
    setMsgCount(data);
  }, [messages]);

  const handleClose = () => {
    setOpen(false);
  };

  const openDoc = (link) => {
    setOpen(true);
    setLocation(link);
  };

  const onError = (e) => {
    console.log("error occured", e);
  };

  const showProfile = (id) => {
    if (id !== loggedInUserId) {
      if (selectedTabUserType === "Artist" && loggedInUserType === "Client") {
        history.push("/client/artist-profile/" + id);
      }
      if (selectedTabUserType === "Client" && loggedInUserType === "Artist") {
        history.push("/artist/client-profile/" + id);
      }
      if (selectedTabUserType === "Artist" && loggedInUserType === "Hichem") {
        history.push("/admin/artist/" + id);
      }
      if (selectedTabUserType === "Client" && loggedInUserType === "Hichem") {
        history.push("/admin/client/project/" + id);
      }
    }
  };

  return (
    <div className="message-list">
      {[...Object.values(messages)]
        .sort((a, b) => a.time - b.time)
        .map((message) => (
          <div key={message.id}>
            <div className="flex flex-row items-center gap-4">
              <div className="w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                {!message.fromUser?.photo &&
                  message.fromUser?.id !== BOSS_USER_ID && (
                    <img
                      onClick={() => showProfile(message.fromUser?.id)}
                      // src={`${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`}
                      src={
                        message.fromUser?.photo
                          ? SERVER_URL + message.fromUser?.photo
                          : SERVER_URL +
                            "uploads/default-images/DefaultProfile.jpeg"
                      }
                      alt="profile_pic"
                      className="w-full h-full object-cover object-center"
                    />
                  )}

                {!message.fromUser?.photo &&
                  message.fromUser?.id === BOSS_USER_ID && (
                    // <img
                    //   src={`${SERVER_URL}uploads/default-images/INKfoundation.png`}
                    //   alt="profile_pic"
                    //   className="w-full h-full object-fill"
                    // />
                    <object
                      type="image/svg+xml"
                      data="/2ndSkin.svg"
                      className="h-8 w-8 pointer-events-none"
                    >
                      2ndSkin
                    </object>
                  )}

                {message.fromUser?.photo &&
                  message.fromUser?.id === BOSS_USER_ID && (
                    // <img
                    //   src={`${SERVER_URL}uploads/default-images/INKfoundation.png`}
                    //   alt="profile_pic"
                    //   className="w-full h-full object-fill"
                    //   onError={({ currentTarget }) => {
                    //     currentTarget.onerror = null; // prevents looping
                    //     currentTarget.src = `${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`;
                    //   }}
                    // />
                    <object
                      type="image/svg+xml"
                      data="/2ndSkin.svg"
                      className="h-8 w-8 pointer-events-none"
                    >
                      2ndSkin
                    </object>
                  )}

                {message.fromUser?.photo &&
                  message.fromUser?.id !== BOSS_USER_ID && (
                    <img
                      onClick={() => showProfile(message.fromUser?.id)}
                      // src={`${message.fromUser?.photo}`}
                      src={
                        message.fromUser?.photo
                          ? SERVER_URL + message.fromUser?.photo
                          : SERVER_URL +
                            "uploads/default-images/DefaultProfile.jpeg"
                      }
                      alt="profile_pic"
                      className="w-full h-full object-cover object-center"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`;
                      }}
                    />
                  )}
              </div>

              <div className="antialiased ... text-sm font-bold">
                {message.fromUser?.id === BOSS_USER_ID
                  ? BOSS_USER_NAME
                  : message?.fromUser?.name}
              </div>
            </div>
            <div
              className={`ml-12 text-sm ${
                message.comment === "" ? "mb-5" : "mb-5"
              }`}
            >
              {message.isFile ? (
                message.comment.includes(".pdf") ? (
                  <a
                    href="#"
                    onClick={() => {
                      setFileType("pdf");
                      openDoc(`${SERVER_URL}${message.comment}`);
                    }}
                  >
                    <img
                      src="/pdf_image.jpeg"
                      width="30"
                      height="30"
                      title="Click to open pdf."
                      alt="PDF"
                    />
                    {message.comment.substring(
                      message.comment.lastIndexOf("/") + 1,
                      message.comment.length
                    )}
                  </a>
                ) : message.comment.includes(".doc") ||
                  message.comment.includes(".docx") ? (
                  <a
                    href="#"
                    onClick={() => {
                      setFileType("docx");
                      openDoc(`${SERVER_URL}${message.comment}`);
                    }}
                  >
                    <img
                      src="/doc_image.jpeg"
                      width="30"
                      height="30"
                      title="Click to open doc."
                      alt="Doc"
                    />
                    {message.comment.substring(
                      message.comment.lastIndexOf("/") + 1,
                      message.comment.length
                    )}
                  </a>
                ) : (
                  <div className="w-12 h-12 border border-primary mr-2">
                    <a
                      href="#"
                      onClick={() => {
                        setFileType("png");
                        openDoc(`${SERVER_URL}${message.comment}`);
                      }}
                    >
                      {" "}
                      <img
                        src={`${SERVER_URL}${message.comment}`}
                        alt={"Comment"}
                        className={"flex h-full w-full"}
                      />
                    </a>
                  </div>
                )
              ) : (
                message.comment
              )}
            </div>
          </div>
        ))}
      <div className="flex align-center justify-center">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="bg-white m-2 p-2 rounded-lg max-w-sm md:max-w-md lg:max-w-xl md:max-h-4/5  align-center justify-center h-4/5 w-full">
            <FileViewer
              fileType={fileType}
              filePath={location}
              onError={onError}
              className="w-full h-full"
            />
            <div className="flex gap-6 mt-4">
              <CustomButton
                width="full"
                textsize="base"
                label="Cancel"
                onClick={handleClose}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default RoomMessages;

import React, { useState } from "react";
import { navigations, sections } from "../CONSTANTS";
import UploadPicturesPage from "./components/UploadPicturesPage";
import Header from "components/Navbars/AuthNavbar";
import PlaceYourTattoPage from "./components/PlaceYourTattoPage";
import BudgetPage from "./components/BudgetPage";
import AddProposedDatePage from "./components/AddProposedDatePage";
import RegisterPage from "./components/RegisterPage";
import "progress-tracker/src/styles/progress-tracker.scss";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations } from "layouts/ClientConstants";
import { Alert } from "@mui/material";
import LocationPage from "./components/LocationPage";
import LoginPage from "./components/LoginPage";
import ForgotPasswordPage from "./components/ForgotPasswordPage";

const ProjectCreateView = (props) => {
  const [registerTitle, setRegisterTitle] = useState("Register");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  let errorMsg = props.error ? (
    <Alert className="m-4 " severity="error">
      {props.error}
    </Alert>
  ) : (
    ""
  );

  const onClickSectionLi = (val) => {
    props.setSelectedSection(val);
  };

  let page, title;
  let s1 = "progress-step",
    s2 = "progress-step",
    s3 = "progress-step",
    s4 = "progress-step",
    s5 = "progress-step",
    s6 = "progress-step";
  let st1 = "bg-indicatorInActive",
    st2 = "bg-indicatorInActive",
    st3 = "bg-indicatorInActive",
    st4 = "bg-indicatorInActive",
    st5 = "bg-indicatorInActive",
    st6 = "bg-indicatorInActive";
  if (props.selectedSection === sections.UploadPictures) {
    page = (
      <UploadPicturesPage
        onSubmit={props.onSubmit}
        colorObj={props.colorDataObj}
        styleObj={props.styleDataObj}
        serviceObj={props.serviceDataObj}
        techniqueObj={props.techniqueDataObj}
        uploadedImages={props.uploadedImages}
        setUploadedImages={props.setUploadedImages}
        storedProjectState={props.storedProjectState}
        setStoredProjectState={props.setStoredProjectState}
      />
    );
    title = "Upload Images";
    s1 = "progress-step is-active";
    st1 = "bg-indicatorActive";
  } else if (props.selectedSection === sections.PlaceYourTatto) {
    page = (
      <PlaceYourTattoPage
        onSubmit={props.onSubmit}
        onBack={props.onBack}
        uploadedImages={props.uploadedImages}
        skinToneObj={props.skinToneDataObj}
        storedProjectState={props.storedProjectState}
        setStoredProjectState={props.setStoredProjectState}
      />
    );
    title = "Place Your Tattoo.";
    s1 = "progress-step is-complete";
    s2 = "progress-step is-active";
    st1 = "bg-indicatorPre";
    st2 = "bg-indicatorActive";
  } else if (props.selectedSection === sections.Budget) {
    page = (
      <BudgetPage
        onSubmit={props.onSubmit}
        onBack={props.onBack}
        storedProjectState={props.storedProjectState}
        setStoredProjectState={props.setStoredProjectState}
      />
    );
    title = "Budget, Asking Price";
    s1 = "progress-step is-complete";
    s2 = "progress-step is-complete";
    s3 = "progress-step is-active";
    st1 = "bg-indicatorPre";
    st2 = "bg-indicatorPre";
    st3 = "bg-indicatorActive";
  } else if (props.selectedSection === sections.DateProposal) {
    page = (
      <AddProposedDatePage
        onSubmit={props.onSubmit}
        onBack={props.onBack}
        loggedInUserId={props.loggedInUserId}
        storedProjectState={props.storedProjectState}
        setStoredProjectState={props.setStoredProjectState}
      />
    );
    title = "Date Proposal";
    s1 = "progress-step is-complete";
    s2 = "progress-step is-complete";
    s3 = "progress-step is-complete";
    s4 = "progress-step is-active";
    st1 = "bg-indicatorPre";
    st2 = "bg-indicatorPre";
    st3 = "bg-indicatorPre";
    st4 = "bg-indicatorActive";
  } else if (props.selectedSection === sections.Location) {
    page = (
      <LocationPage
        onSubmit={props.onSubmit}
        onBack={props.onBack}
        loggedInUserId={props.loggedInUserId}
        storedProjectState={props.storedProjectState}
        setStoredProjectState={props.setStoredProjectState}
      />
    );
    title = "Location";
    s1 = "progress-step is-complete";
    s2 = "progress-step is-complete";
    s3 = "progress-step is-complete";
    s4 = "progress-step is-complete";
    s5 = "progress-step is-active";
    st1 = "bg-indicatorPre";
    st2 = "bg-indicatorPre";
    st3 = "bg-indicatorPre";
    st4 = "bg-indicatorPre";
    st5 = "bg-indicatorActive";
  } else if (
    (props.selectedSection === sections.Finish ||
      props.selectedSection === sections.Submit) &&
    !props.loggedInUserId
  ) {
    page = (
      <RegisterPage
        onSubmit={props.onSubmit}
        onBack={props.onBack}
        storedProjectState={props.storedProjectState}
        setStoredProjectState={props.setStoredProjectState}
        setSelectedSection={props.setSelectedSection}
        setRegisterTitle={setRegisterTitle}
        storedRegisterPageState={props.storedRegisterPageState} 
        setStoredRegisterPageState={props.setStoredRegisterPageState}
      />
    );
    title = registerTitle;
    s1 = "progress-step is-complete";
    s2 = "progress-step is-complete";
    s3 = "progress-step is-complete";
    s4 = "progress-step is-complete";
    s5 = "progress-step is-complete";
    s6 = "progress-step is-active";
    st1 = "bg-indicatorPre";
    st2 = "bg-indicatorPre";
    st3 = "bg-indicatorPre";
    st4 = "bg-indicatorPre";
    st5 = "bg-indicatorPre";
    st6 = "bg-indicatorActive";
  } else if (props.selectedSection === sections.Login) {
    page = (
      <LoginPage
        onBack={props.onBack}
        storedProjectState={props.storedProjectState}
        setSelectedSection={props.setSelectedSection}
        setShouldBlockNavigation={props.setShouldBlockNavigation}
      />
    );
    title = "Login";
    s1 = "progress-step is-complete";
    s2 = "progress-step is-complete";
    s3 = "progress-step is-complete";
    s4 = "progress-step is-complete";
    s5 = "progress-step is-complete";
    s6 = "progress-step is-active";
    st1 = "bg-indicatorPre";
    st2 = "bg-indicatorPre";
    st3 = "bg-indicatorPre";
    st4 = "bg-indicatorPre";
    st5 = "bg-indicatorPre";
    st6 = "bg-indicatorActive";
  } else if (props.selectedSection === sections.ForgotPassword) {
    page = (
      <ForgotPasswordPage
        onBack={props.onBack}
        storedProjectState={props.storedProjectState}
        setSelectedSection={props.setSelectedSection}
      />
    );
    title = "Forgot Password";
    s1 = "progress-step is-complete";
    s2 = "progress-step is-complete";
    s3 = "progress-step is-complete";
    s4 = "progress-step is-complete";
    s5 = "progress-step is-complete";
    s6 = "progress-step is-active";
    st1 = "bg-indicatorPre";
    st2 = "bg-indicatorPre";
    st3 = "bg-indicatorPre";
    st4 = "bg-indicatorPre";
    st5 = "bg-indicatorPre";
    st6 = "bg-indicatorActive";
  }

  // populate colors
  if (navigations && navigations.length > 0) {
    navigations.map((section) => (
      <span className="mb-10">
        <label className="font-sans text-secondary">
          <input
            type="radio"
            name="section"
            value={section.id}
            className="form-radio mr-2 text-primary"
          />
          <span className="">{section.name}</span>
        </label>
      </span>
    ));
  }
  return (
    <div className="h-full w-full flex">
      {/* Content area */}
      <div className="flex flex-1 flex-col">
        {!props.loginData && (
          <header>
            <Header className="" fromCreateProject={true} />
          </header>
        )}
        {props.loginData && (
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={props.loginData}
            clientview={false}
          />
        )}

        <div className="grid xl:grid-cols-12 lg:grid-cols-10 md:mx-12 gap-2">
          <div className="col-start-1 xl:col-span-4 lg:col-span-3 flex flex-1 py-0 hidden lg:flex">
            {/* <div className="hidden lg:flex lg:flex-shrink-1 flex flex-1 lg:pl-5 xl:pl-10 2xl:pl-20 py-40"> */}
            <ul className="progress-tracker progress-tracker--vertical progress-tracker--theme-orange z-0">
              <li
                className={s1}
                onClick={() => onClickSectionLi(sections.UploadPictures)}
              >
                <div className="progress-marker"></div>
                <div className="progress-text">
                  <h5 className="progress-title">Upload Images</h5>
                </div>
              </li>
              <li
                className={s2}
                onClick={() => onClickSectionLi(sections.PlaceYourTatto)}
              >
                <div className="progress-marker"></div>
                <div className="progress-text">
                  <h5 className="progress-title">Place your Tattoo</h5>
                </div>
              </li>
              <li
                className={s3}
                onClick={() => onClickSectionLi(sections.Budget)}
              >
                <div className="progress-marker"></div>
                <div className="progress-text">
                  <h5 className="progress-title">Budget</h5>
                </div>
              </li>
              <li
                className={s4}
                onClick={() => onClickSectionLi(sections.DateProposal)}
              >
                <div className="progress-marker"></div>
                <div className="progress-text">
                  <h5 className="progress-title">Date Proposal</h5>
                </div>
              </li>
              <li
                className={s5}
                onClick={() => onClickSectionLi(sections.Location)}
              >
                <div className="progress-marker"></div>
                <div className="progress-text">
                  <h5 className="progress-title">Location</h5>
                </div>
              </li>
              {!props.loggedInUserId && (
                <li
                  className={s6}
                  onClick={() => onClickSectionLi(sections.Finish)}
                >
                  <div className="progress-marker"></div>
                  <div className="progress-text">
                    <h5 className="progress-title">Finish</h5>
                  </div>
                </li>
              )}
            </ul>
          </div>
          {/* Main content */}
          <div className="xl:col-end-13 xl:col-span-8 lg:col-end-11 lg:col-span-7">
            {/* <div className="md:col-span-4 xl:col-span-3 bg-white sm:m-0"> */}
            <main className="overflow-hidden">
              <div className="bg-warm-gray-50">
                <div className="py-0 lg:py-0">
                  <div className="relative z-5 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                    <h1 className="mt-0 pt-0 text-3xl font-bold text-primary font-sans">
                      {title}
                    </h1>
                    <div className="space-x-1 flex flex-row mt-1 lg:hidden">
                      <div className={`h-1.5 rounded-lg ${st1} flex flex-1`}></div>
                      <div className={`h-1.5 rounded-lg ${st2} flex flex-1`}></div>
                      <div className={`h-1.5 rounded-lg ${st3} flex flex-1`}></div>
                      <div className={`h-1.5 rounded-lg ${st4} flex flex-1`}></div>
                      <div className={`h-1.5 rounded-lg ${st5} flex flex-1`}></div>
                      {!props.loggedInUserId && (
                        <div className={`h-1.5 rounded-lg ${st6} flex flex-1`}></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <section
                className="relative bg-white"
                aria-labelledby="manager-add-heading"
              >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="relative bg-white">
                    <div className="">{page}</div>
                  </div>
                  {errorMsg}
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCreateView;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ProjectDetailsView from "./ProjectDetailsView";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectDetails,
  validateUserAndProject,
  updateProject,
  updateTempProjectDetails,
} from "../../../redux/actions/ProjectAction";
import { useHistory } from "react-router-dom";
import {
  getUserRole,
  USER_ROLE_BOSS,
  USER_ROLE_ADMIN,
  USER_ROLE_MANAGER,
  USER_ROLE_PROJECT_ADMIN,
  USER_ROLE_ARTIST,
  USER_ROLE_PROJECT_MYSTUDIO_ARTIST,
  getUserType,
} from "../CONSTANTS";
import {
  BOSS_USER_NAME,
  SERVER_URL,
  USER_ROLES,
  BOSS_USER_ID,
} from "utils/constants";
import { getUsersByRoleId } from "../../../redux/actions/UserActions";
import {
  createSupportChat,
  getUnreadCommentChatsForUser,
  getUnreadSupportChatsForUser,
  setReadStatusforAdminChat,
} from "redux/actions/SupportAction";
import { socket } from "utils/socketContext";
import { getUnreadCountClientArtistChatsForAdmin } from "redux/actions/SupportAction";

const ProjectDetailsContainer = (props) => {
  const projectDataObj = useSelector((state) => state.project);
  const bossDataObj = useSelector((state) => state.user);
  const projectId = props.match.params.id;
  let history = useHistory();
  const dispatch = useDispatch();
  const [error, setErrorState] = useState();
  const [isSupportNewMessage, setIsSupportNewMessage] = useState(false);
  const [isCommentNewMessage, setIsCommentNewMessage] = useState(false);
  const [chatCountAdmin, setChatCountAdmin] = useState([]);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    // listens for the event project notification update from the backend
    if (socket && props.loginData.id) {
      socket.on("projectChangeNofication", (isTrue) => {
        if (isTrue) {
          dispatch(validateUserAndProject(props.loginData.id, projectId)).then(
            (res) => {
              if (res.type === "PROJECT_VALIDATE_SUCCESS") {
                onChangeValidateStatus(res.payload);
              }
            }
          );
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (props.loginData.id && projectId) {
      setShowProgress(true);
      dispatch(validateUserAndProject(props.loginData.id, projectId)).then(
        (res) => {
          if (res.type === "PROJECT_VALIDATE_SUCCESS") {
            onChangeValidateStatus(res.payload);
          }
          setShowProgress(false);
        }
      );
    }
  }, [projectId]);

  const onChangeValidateStatus = (canAccess) => {
    if (canAccess === 0) {
      history.goBack();
    }
    if (canAccess === 1) {
      dispatch(getProjectDetails(projectId));
      dispatch(getUsersByRoleId(USER_ROLES.BOSS));
      dispatch(
        getUnreadSupportChatsForUser(projectId, props.loginData.id)
      ).then((res) => {
        let resArray = res.payload;
        let iniArray = [];
        if (resArray && resArray.length > 0) {
          resArray.map((o) => {
            iniArray[o.createdBy] = o.count;
          });
        }
        const countSupport = iniArray.filter((x) => x > 0).length;
        handleSupportCount(countSupport);
      });

      dispatch(
        getUnreadCommentChatsForUser(projectId, props.loginData.id)
      ).then((res) => {
        let resArray = res.payload;
        let iniArray = [];
        if (resArray && resArray.length > 0) {
          resArray.map((o) => {
            if (o.isCommonRoomChat) {
              iniArray[999999] = o.count;
            } else {
              iniArray[o.createdBy] = o.count;
            }
          });
        }
        const countSupport = iniArray.filter((x) => x > 0).length;
        handleCommentCount(countSupport);
      });
    }
  };

  const handleSupportCount = (count) => {
    count > 0 ? setIsSupportNewMessage(true) : setIsSupportNewMessage(false);
  };

  const handleCommentCount = (count) => {
    count > 0 ? setIsCommentNewMessage(true) : setIsCommentNewMessage(false);
  };

  const getChatCountforAdmin = (projectId, artistId, client) => {
    if (projectId && artistId && client) {
      dispatch(
        getUnreadCountClientArtistChatsForAdmin(projectId, artistId, client)
      ).then((res) => {
        let resArray = res.payload;
        let updateChatCountAdmin = chatCountAdmin.map((item) =>
          item.id === artistId && resArray.count > 0
            ? { ...item, isCount: true }
            : { ...item, isCount: false }
        );
        setChatCountAdmin(updateChatCountAdmin);
      });
    }
  };

  const setReadStatusforAdmin = (projectId, artistId, client) => {
    if (projectId && artistId && client) {
      let data = {
        projectId: projectId,
        artistId: artistId,
        clientId: client,
      };
      dispatch(setReadStatusforAdminChat(data)).then((res) => {
        console.log("setReadStatusforAdminChat" + res);
      });
    }
  };

  const onSubmit = (projectObj, chatObj) => {
    dispatch(updateProject(projectId, projectObj)).then((item) => {
      if (chatObj) {
        dispatch(createSupportChat(chatObj));
      }
      dispatch(getProjectDetails(projectId)).then((item) => {});
    });
  };

  const onTempSubmit = (projectObj) => {
    dispatch(updateTempProjectDetails(projectId, projectObj));
  };

  let role;
  const user_role = getUserRole(props?.loginData?.userRoleId);

  if (
    user_role === USER_ROLE_BOSS ||
    user_role === USER_ROLE_ADMIN ||
    user_role === USER_ROLE_MANAGER
  ) {
    role = USER_ROLE_PROJECT_ADMIN;
  } else if (user_role === USER_ROLE_ARTIST) {
    role = USER_ROLE_PROJECT_MYSTUDIO_ARTIST;
  } else {
    role = user_role;
  }
  const user_type = getUserType(props?.loginData?.userTypeId);

  return (
    <div>
      <ProjectDetailsView
        error={error}
        onSubmit={onSubmit}
        projectDataObj={projectDataObj?.dataGet}
        role={role}
        loggedInUserId={
          role === USER_ROLE_PROJECT_ADMIN
            ? BOSS_USER_ID // bossDataObj?.usersByRole?.[0].id
            : props.loginData.id
        }
        loginData={props?.loginData}
        title="Project Details View"
        projectError={projectDataObj.error}
        loggedInUserName={
          role === USER_ROLE_PROJECT_ADMIN
            ? BOSS_USER_NAME
            : props.loginData.firstName + " " + props.loginData.lastName
        }
        loggedInUserPhotoUrl={
          role === USER_ROLE_PROJECT_ADMIN
            ? `${SERVER_URL}uploads/default-images/INKfoundation.png`
            : props.loginData.photoUrl
        }
        projectId={projectId}
        onTempSubmit={onTempSubmit}
        bossDataObj={bossDataObj?.usersByRole}
        isSupportNewMessage={isSupportNewMessage}
        handleSupportCount={handleSupportCount}
        isCommentNewMessage={isCommentNewMessage}
        handleCommentCount={handleCommentCount}
        loggedInUserType={user_type}
        getChatCountforAdmin={getChatCountforAdmin}
        setChatCountAdmin={setChatCountAdmin}
        chatCountAdmin={chatCountAdmin}
        setReadStatusforAdmin={setReadStatusforAdmin}
        showProgress={showProgress}
      />
    </div>
  );
};

export default ProjectDetailsContainer;

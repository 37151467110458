/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BOSS_USER_ID, BOSS_USER_NAME, SERVER_URL } from "utils/constants";
import FileViewer from "react-file-viewer";
import { Modal } from "@mui/material";
import CustomButton from "components/UI/Button";
import "../../styles/pdf.css";
import { socket } from "utils/socketContext";

const ClientArtistLiveChat = (props) => {
  const [messages, setMessages] = useState({});
  const [fileType, setFileType] = useState("");
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState("");

  if (props?.projectId && socket) {
    let username =
      props.projectId + "$" + props.loggedInUserId + "$ClientArtistChat";
    socket.emit("register", username);
  }

  useEffect(() => {
    setMessages({});
    let aData = props?.data.split("#");
    const reqObj = {
      msgToSocket: props?.projectId + "#" + aData[1] + "#C",
      msgFromSocket: props?.projectId + "#" + aData[0] + "#C",
      projectId: props?.projectId,
      dontMarkRead: true,
      isAdmin:
        props.projectId + "$" + props.loggedInUserId + "$ClientArtistChat",
    };
    socket.emit("getMessages", reqObj);
  }, [socket, props]);

  useEffect(() => {
    let aData = props?.data.split("#");

    const messageListener = (message) => {
      if (
        (message.msgFromSocket === props?.projectId + "#" + aData[0] + "#C" ||
          message.msgFromSocket === props?.projectId + "#" + aData[1] + "#C") &&
        (message.msgToSocket === props?.projectId + "#" + aData[0] + "#C" ||
          message.msgToSocket === props?.projectId + "#" + aData[1] + "#C")
      ) {
        setMessages((prevMessages) => {
          const newMessages = { ...prevMessages };
          newMessages[message.id] = message;
          return newMessages;
        });
      }
    };

    socket.on("message", messageListener);
    return () => {
      socket.off("message", messageListener);
    };
  }, [socket, props]);

  let show = false;
  if (props?.projectStatus !== "Project Done") {
    show = true;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const openDoc = (link) => {
    setOpen(true);
    setLocation(link);
  };

  const onError = (e) => {
    console.log("error occured", e);
  };

  return (
    <div className="flex flex-col gap-4 w-full text-sm">
      <div className="message-list">
        {[...Object.values(messages)]
          .sort((a, b) => a.time - b.time)
          .map((message) => (
            <div key={message.id}>
              <div className="flex flex-row items-center gap-4">
                <div className="w-8 h-8 rounded-full overflow-hidden flex items-center justify-center">
                  {!message.fromUser?.photo &&
                    message.fromUser?.id !== BOSS_USER_ID && (
                      <img
                        src={
                          message.fromUser?.photo
                            ? SERVER_URL + message.fromUser?.photo
                            : SERVER_URL +
                              "uploads/default-images/DefaultProfile.jpeg"
                        }
                        alt="profile_pic"
                        className="w-full h-full object-cover object-center"
                      />
                    )}

                  {message.fromUser?.photo &&
                    message.fromUser?.id !== BOSS_USER_ID && (
                      <img
                        src={
                          message.fromUser?.photo
                            ? SERVER_URL + message.fromUser?.photo
                            : SERVER_URL +
                              "uploads/default-images/DefaultProfile.jpeg"
                        }
                        alt="profile_pic"
                        className="w-full h-full object-cover object-center"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = `${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`;
                        }}
                      />
                    )}
                </div>

                <div className="antialiased ... text-sm font-bold">
                  {message.fromUser?.id === BOSS_USER_ID
                    ? BOSS_USER_NAME
                    : message?.fromUser?.name}
                </div>
              </div>
              <div className="text-sm ml-12 mb-5">
                {message.isFile ? (
                  message.comment.includes(".pdf") ? (
                    // <a href={`${SERVER_URL}${message.comment}`} target="_blank" rel="noreferrer">
                    <a
                      href="#"
                      onClick={() => {
                        setFileType("pdf");
                        openDoc(`${SERVER_URL}${message.comment}`);
                      }}
                    >
                      <img
                        src="/pdf_image.jpeg"
                        width="30"
                        height="30"
                        title="Click to open pdf."
                        alt="PDF"
                      />
                      {message.comment.substring(
                        message.comment.lastIndexOf("/") + 1,
                        message.comment.length
                      )}
                    </a>
                  ) : message.comment.includes(".doc") ||
                    message.comment.includes(".docx") ? (
                    <a
                      href="#"
                      onClick={() => {
                        setFileType("docx");
                        openDoc(`${SERVER_URL}${message.comment}`);
                      }}
                    >
                      <img
                        src="/doc_image.jpeg"
                        width="30"
                        height="30"
                        title="Click to open doc."
                        alt="Doc"
                      />
                      {message.comment.substring(
                        message.comment.lastIndexOf("/") + 1,
                        message.comment.length
                      )}
                    </a>
                  ) : (
                    <div className="w-12 h-12 border border-primary mr-2">
                      {/* <a href={`${SERVER_URL}${message.comment}`} target="_blank" rel="noreferrer"> */}
                      <a
                        href="#"
                        onClick={() => {
                          setFileType("png");
                          openDoc(`${SERVER_URL}${message.comment}`);
                        }}
                      >
                        <img
                          src={`${SERVER_URL}${message.comment}`}
                          alt={"Comment"}
                          className={"flex h-full w-full"}
                        />
                      </a>
                    </div>
                  )
                ) : (
                  message.comment
                )}
              </div>
            </div>
          ))}
      </div>
      {Object.keys(messages).length === 0 &&
        messages.constructor === Object && <div>{"No messages"}</div>}

      <div className="flex align-center justify-center">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="bg-white m-2 p-2 rounded-lg max-w-sm md:max-w-md lg:max-w-xl md:max-h-4/5 align-center justify-center h-4/5 w-full">
            <FileViewer
              fileType={fileType}
              filePath={location}
              onError={onError}
              className="w-full h-full"
            />

            <div className="flex gap-6 mt-4">
              <CustomButton
                width="full"
                textsize="base"
                label="Cancel"
                onClick={handleClose}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ClientArtistLiveChat;

import { withRouter } from "react-router-dom";

const VerifyEmail = (props) => {

  const handleFormSubmit = () => {           
    props.onSubmit();
  };

  let msg;
  if (!props.data) {
    msg = 'Please login and check if the email address has been already verified or else contact system admin.';
  } else {
    handleFormSubmit();
  }
  return (
    <>
    <div className="min-h-screen flex">
      <div className="mt-18">
        {props.error}   {msg}    
      </div>
    </div>     
    </>
      );
}

export default withRouter(VerifyEmail);
import axios from "../http-config";

class UserService {
  create(data) {
    return axios({
      method: "POST",
      url: `/users`,
      data,
    });
  }

  getUserByEmail(id) {
    return axios({
      method: "GET",
      url: `/users/email/${id}`,
    });
  }

  getUsersByRoleId(id) {
    return axios({
      method: "GET",
      url: `/users/role/${id}`,
    });
  }

  resendVerificationMail(data) {
    return axios({
      method: "PATCH",
      url: `/users/resend-mail/${data.id}`,
      data,
    });
  }


}
export default new UserService();

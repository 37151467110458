/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import SweetAlert2 from "react-sweetalert2";

export function RouterPrompt(props) {
  const { when, okText, cancelText } = props;

  const history = useHistory();
  const unblockRef = useRef();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    unblockRef.current = history.block((prompt) => {
      if (when) {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false;
      }
      return true;
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when]);

  function handleOK() {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  }

  function handleCancel() {
    setShowPrompt(false);
  }

  return showPrompt ? (
    <SweetAlert2
      icon="warning"
      show={showPrompt}
      title={"Warning!!!"}
      text={
        " There are unsaved changes. Are you sure want to leave this page ?"
      }
      confirmButtonText={okText}
      onConfirm={handleOK}
      showCancelButton={true}
      onResolve={handleCancel}
      cancelButtonText={cancelText}
      showCloseButton={true}
    />
  ) : null;
}

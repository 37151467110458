/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Budget from "./components/Budget";
import Buttons from "./components/Buttons";
import ClientsInfo from "./components/ClientsInfo";
import Comments from "./components/Comments";
import Dimensions from "./components/Dimensions";
import ProjectInfo from "./components/ProjectInfo";
import Rating from "./components/Rating";
import RegularDates from "./components/RegularDates";
import Styles from "./components/Styles";
import TattooImages from "./components/TattooImages";
import ArtistInfo from "./components/ArtistInfo";
import {
  getProjectStatus,
  getProjectStatusByName,
  USER_ROLE_PROJECT_ADMIN,
  USER_ROLE_PROJECT_MYSTUDIO_ARTIST,
  USER_ROLE_CLIENT,
  USER_ROLE_ADMIN,
} from "../CONSTANTS";
import DeclineProject from "./components/DeclineProject";
import Support from "./components/Support";
import OfferPrice from "./components/OfferPrice";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import CircularProgress from '@mui/material/CircularProgress';
import {
  navigations,
  USER_TYPE_CLIENT,
  USER_TYPE_ARTIST,
  userNavigations,
  USER_ROLE_MANGER_ID,
} from "layouts/AdminConstants";
import { ReactComponent as Withdraw } from "../../../assets/ic_withdraw.svg";
import { HIDE_DISABLE_FLAG, USER_TYPES } from "../../../utils/constants";
import Techniques from "./components/Techniques";
import ClientArtistLiveChat from "./components/ClientArtistLiveChat";
import Swal from "sweetalert2";
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#C7A33F',
  },
  '& .MuiRating-iconHover': {
    color: '#AFAFAF',
  },
  '& .MuiRating-iconEmpty': {
    color: '#AFAFAF',
  },
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProjectDetailsView = (props) => {
  let clientview = false;
  if (props.role === USER_ROLE_CLIENT) {
    userNavigations[1].href = "/client/signout";
    userNavigations[0].href = "/client/profile";
    clientview = true;
  } else if (props.role === USER_ROLE_ADMIN) {
    userNavigations[1].href = "/admin/signout";
    userNavigations[0].href = "/admin/profile";
  } else if (props.role === USER_ROLE_PROJECT_MYSTUDIO_ARTIST) {
    userNavigations[1].href = "/artist/signout";
    userNavigations[0].href = "/artist/profile";
  }
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);
  const [isReloaded, setIsReloaded] = useState(false);

  const handleDeclineOpen = () => setDeclineOpen(true);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  let details = {};

  if (!props.projectError && props?.projectDataObj) {
    details = JSON.parse(JSON.stringify(props.projectDataObj));
  }

  let projectInfo,
    budget,
    regularDates,
    styles,
    techniques,
    comments,
    tatooImages,
    dimensions,
    clientsinfo,
    projectStatus,
    projectStatusId,
    artistId,
    ratings,
    acceptedArtist,
    isInMarketPlace,
    isPaymentDone,
    offerPriceVal,
    addDiscount,
    clientId,
    supportChatUsers = [],
    projectGender,
    projectSkinToneUrl,
    projectSkinToneId,
    commentChatUsers = [],
    modelPictureUrl,
    isPriceNegotiated,
    isTatooAround;

  let projectId = props?.projectId;
  let nloggedInUserId = props?.loggedInUserId;
 
  const [marketVisibility, setMarketVisibility] = useState(true);
  const [withdrawVisibility, setWithdrawVisibility] = useState(true);
  const [isSupport, setSupport] = useState(false);
  const [textTabName, setTextTabName] = useState("comment");

  if (details) {
    projectStatus = getProjectStatus(details.projectStatusId);

    if (details.project_status) {
      projectInfo = {
        statusData: details.project_status,
        id: details.id,
        created: details.createdAt,
      };
    }
    if (details.client_proposed_appointment_dates) {
      regularDates = details.client_proposed_appointment_dates;
    }

    if (details.client_proposed_styles) {
      styles = details.client_proposed_styles;
    }

    if (details.client_proposed_techniques) {
      techniques = details.client_proposed_techniques;
    }

    if (details.project_comments) {
      comments = details.project_comments;
    }

    if (details.artist_ratings) {
      ratings = details.artist_ratings;
    }

    if (details.project_tatoo_images) {
      tatooImages = details.project_tatoo_images;
    }

    if (details.user) {
      clientsinfo = details.user;
      clientId = details.userId;
    }

    if (details.counter_offers) {
      acceptedArtist = details.counter_offers;
    }

    dimensions = { height: details.tatooHeight, width: details.tatooWidth };
    budget = {
      fromPrice: details.budgetPriceFrom,
      toPrice: details.budgetPriceTo,
    };
    artistId = details.finalArtistId;
    isInMarketPlace = details.isInMarketPlace;
    offerPriceVal = details.offerPrice;
    isPaymentDone = details.isPaymentDone;
    addDiscount = details.addDiscount;
    projectStatusId = details.projectStatusId;
    projectGender = details.gender;
    projectSkinToneUrl = details.skin_tone?.imageUrl;
    projectSkinToneId = details.skinToneId;
    modelPictureUrl = details.modelPictureUrl;
    isPriceNegotiated = details.isPriceNegotiated;
    isTatooAround = details.isAround;
  }

  // ---Support / Comment  menu data

  commentChatUsers.push({
    id: 999999,
    commentTicketId: projectId,
    firstName: "Common Chat Room",
    userType: "",
  });

  // Admin Tab for chat
  if (props.role !== USER_ROLE_PROJECT_ADMIN) {
    if (props?.bossDataObj && props?.bossDataObj.length > 0) {
      props.bossDataObj.map((v) => {
        supportChatUsers.push({
          id: v.id,
          supportTicketId: projectId + "#" + v.id + "#S",
          firstName: v.firstName,
          userType: USER_TYPES.HISHEM.name,
        });

        commentChatUsers.push({
          id: v.id,
          commentTicketId: projectId + "#" + v.id + "#C",
          firstName: v.firstName,
          userType: USER_TYPES.HISHEM.name,
        });
      });
    }
  }
  
  // client Tab for chat 
  if (props.role !== USER_ROLE_CLIENT && clientsinfo) {
    // Client can be only shown to Hichem or Manager in Support Area
    if (props.role !== USER_ROLE_PROJECT_MYSTUDIO_ARTIST) {
      supportChatUsers.push({
        id: clientId,
        supportTicketId: projectId + "#" + clientId + "#S",
        firstName: clientsinfo.firstName,
        userType:
          clientsinfo.userTypeId == USER_TYPES.HISHEM.id
            ? USER_TYPES.HISHEM.name
            : clientsinfo.userTypeId == USER_TYPES.CLIENT.id
            ? USER_TYPES.CLIENT.name
            : clientsinfo.userTypeId == USER_TYPES.ARTIST.id
            ? USER_TYPES.ARTIST.name
            : "",
      });
    }

    commentChatUsers.push({
      id: clientId,
      commentTicketId: projectId + "#" + clientId + "#C",
      firstName: clientsinfo.firstName,
      userType:
        clientsinfo.userTypeId == USER_TYPES.HISHEM.id
          ? USER_TYPES.HISHEM.name
          : clientsinfo.userTypeId == USER_TYPES.CLIENT.id
          ? USER_TYPES.CLIENT.name
          : clientsinfo.userTypeId == USER_TYPES.ARTIST.id
          ? USER_TYPES.ARTIST.name
          : "",
    });

    

  }

  let artistFiltered = [];
  let allArtistHistory = [];

  // for refund status do not show artists in chat section for admin as well as client

  if (acceptedArtist && projectStatus !== 'Refunded') {
    artistFiltered = acceptedArtist.filter(
      (item) => item.statusId === 9 || item.statusId === 10
    );
    if (artistFiltered.length <= 0) {
      artistFiltered = acceptedArtist;
    }
    allArtistHistory = acceptedArtist.filter(
      (item) =>
        item.statusId === 9 || item.statusId === 10 || item.statusId === 0
    );
  }
 
  if (props.role !== USER_ROLE_PROJECT_MYSTUDIO_ARTIST && acceptedArtist) {
    artistFiltered.map((item) => {
      // Artist can be shown to Hichem or Manager in Support Area
      if (props.role !== USER_ROLE_CLIENT) {
        supportChatUsers.push({
          id: item.userId,
          supportTicketId: projectId + "#" + item.userId + "#S",
          firstName: item.user.firstName,
          userType:
            item.user.userTypeId == USER_TYPES.HISHEM.id
              ? USER_TYPES.HISHEM.name
              : item.user.userTypeId == USER_TYPES.CLIENT.id
              ? USER_TYPES.CLIENT.name
              : item.user.userTypeId == USER_TYPES.ARTIST.id
              ? USER_TYPES.ARTIST.name
              : "",
        });
      }

      // if price is negotiated by client do not show artists for hischem in Comments Section
      if (!((props.role === USER_ROLE_PROJECT_ADMIN || props.role === USER_ROLE_CLIENT)  && projectStatus === "New Project" && isPriceNegotiated)) {
        commentChatUsers.push({
          id: item.userId,
          commentTicketId: projectId + "#" + item.userId + "#C",
          firstName: item.user.firstName,
          userType:
            item.user.userTypeId == USER_TYPES.HISHEM.id
              ? USER_TYPES.HISHEM.name
              : item.user.userTypeId == USER_TYPES.CLIENT.id
              ? USER_TYPES.CLIENT.name
              : item.user.userTypeId == USER_TYPES.ARTIST.id
              ? USER_TYPES.ARTIST.name
              : "",
        });
      }      
    });
  }

  // --- support menu data ends

  let cShow = false,
    adShow = false;
  if (props?.role === USER_ROLE_PROJECT_ADMIN) {
    adShow = true;
  } else if (props?.role === USER_ROLE_CLIENT) {
    cShow = true;
  }

  const pStatusNames = [
    "Artist Confrimed",
    "Appointment Date Confirmation",
    "Appointment Date Confirmed",
    "Project Done",
    "Refunded"
  ];
  let buttonShow = pStatusNames.includes(projectStatus);

  let errorMsg = props.error ? (
    <p className="mt-6 text-red text-center ml-10">{props.error}</p>
  ) : (
    ""
  );

  const moveToMarket = (a) => {
    setMarketVisibility(false);
    let obj = {
      market: {
        isInMarketPlace: true,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  const withdrawProject = (a) => {
    setWithdrawVisibility(false);
    let obj = {
      status: {
        statusId: getProjectStatusByName.Withdrawn_Project,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  const recreateProject = (a) => {
    setWithdrawVisibility(true);
    let obj = {
      recreate: {
        statusId: getProjectStatusByName.New_Project,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  const confirmWithdrawProject = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to withdraw the project?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        withdrawProject();
      }
    });
  };

  useEffect(() => {
    if (props?.role === USER_ROLE_PROJECT_ADMIN && allArtistHistory.length > 0) 
    {
      allArtistHistory.map((item) => {
        let newValueChat = { id: item.userId, isCount: false };
        let cntChtArry = props?.chatCountAdmin;
        cntChtArry.push(newValueChat);
        props?.setChatCountAdmin(cntChtArry);
        props?.getChatCountforAdmin(projectId, item.userId, clientId);
      });
      setIsReloaded(true);
    }
  }, [isReloaded == false]);

  
  return (
    <>
      <div className="h-full flex">
        <div className="flex-1 flex flex-col overflow-hidden h-full">
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={props.loginData}
            clientview={clientview}
          />
          <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
            {props.loginData.userTypeId != USER_TYPE_CLIENT &&
              props.loginData.userTypeId != USER_TYPE_ARTIST && (
                <SideNavBar
                  navigations={navigations(
                    props.loginData.userRoleId !== USER_ROLE_MANGER_ID
                  )}
                  mobileMenuOpen={mobileMenuOpen}
                  handleMenu={handleMenuOpen}
                  bg={"white"}
                  loginID={props.loginData.id}
                />
              )}
            <div className="flex flex-1">
              {!props.showProgress && (
                <main className="md:flex flex-1 my-4 md:grid md:grid-cols-12 md:mx-4 md:gap-2 lg:gap-4">
                  {/* <div className="flex flex-1 grid grid-cols-12"> */}
                  {/* Left Panel */}
                  <div className="md:flex md:col-start-1 md:col-span-8 lg:col-start-1 lg:col-span-8 xl:col-start-2 xl:col-span-7 md:grid md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-7 ">
                    {/* <div className="flex flex-col w-full gap-8"> */}
                    <div className="flex col-start-1 col-span-8 lg:col-span-5 xl:col-span-3 flex-1 mx-5 md:mx-0">
                      <TattooImages
                        data={tatooImages}
                        projectStatus={projectStatus}
                        role={props.role}
                        onSubmit={props.onSubmit}
                        loggedInUserId={props.loggedInUserId}
                        modelPicture={modelPictureUrl}
                      />
                    </div>
                    <div className="flex flex-col col-start-1 col-span-8 lg:col-start-7 lg:col-span-4 xl:col-start-5 xl:col-span-3 m-5 md:m-0">
                      <ProjectInfo
                        data={projectInfo}
                        projectStatus={projectStatus}
                        role={props.role}
                        projectStatusId={projectStatusId}
                      />
                      <Dimensions
                        data={dimensions}
                        projectStatus={projectStatus}
                        role={props.role}
                        skinToneUrl={projectSkinToneUrl}
                        skinToneId={projectSkinToneId}
                        onSubmit={props.onSubmit}
                        loggedInUserId={props.loggedInUserId}
                        isTatooAround={isTatooAround}
                      />
                      <Styles
                        data={styles}
                        projectStatus={projectStatus}
                        role={props.role}
                        onSubmit={props.onSubmit}
                        loggedInUserId={props.loggedInUserId}
                      />
                      <Techniques
                        data={techniques}
                        projectStatus={projectStatus}
                        role={props.role}
                        onSubmit={props.onSubmit}
                        loggedInUserId={props.loggedInUserId}
                      />
                    </div>
                    <div className="flex col-start-1 col-span-8 lg:col-span-9 xl:col-span-6 ml-5 mr-5 md:ml-0 md:mr-0 mt-6 mb-5 lg:mt-5 lg:mb-0 flex-col">
                      <nav
                        className={`flex bg-primaryLight items-center mb-5 flex-col lg:flex-row`}
                        aria-label="Tabs"
                      >
                        <div
                          key={"comment"}
                          onClick={() => {
                            setSupport(false);
                            setTextTabName("comment");
                          }}
                          className={classNames(
                            textTabName === "comment"
                              ? "bg-primary text-white "
                              : "border-transparent text-tertiary",
                            "font-normal text-base font-sans pl-5 pr-5 py-2 w-full text-center flex justify-center items-center"
                          )}
                        >
                          Comments{" "}
                          {props.isCommentNewMessage ? (
                            <div className="h-2 w-2 bg-progress rounded-full ml-1"></div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          key={"support"}
                          onClick={() => {
                            setSupport(true);
                            setTextTabName("support");
                          }}
                          className={classNames(
                            textTabName === "support"
                              ? "bg-primary text-white"
                              : "border-transparent text-tertiary",
                            "font-normal text-base font-sans py-2 pr-5 w-full text-center flex justify-center items-center"
                          )}
                        >
                          Support{" "}
                          {props.isSupportNewMessage ? (
                            <div className="h-2 w-2 bg-progress rounded-full ml-1"></div>
                          ) : (
                            ""
                          )}
                        </div>
                        {props?.role === USER_ROLE_PROJECT_ADMIN &&
                          allArtistHistory.map((item) => (
                            <div
                              key={clientId + "#" + item.userId}
                              onClick={() => {
                                setTextTabName(clientId + "#" + item.userId);
                                props?.setReadStatusforAdmin(
                                  projectId,
                                  item.userId,
                                  clientId
                                );
                              }}
                              className={classNames(
                                textTabName === clientId + "#" + item.userId
                                  ? "bg-primary text-white"
                                  : "border-transparent text-tertiary",
                                "font-normal text-base font-sans pr-5 py-2 w-full text-center flex justify-center items-center"
                              )}
                            >
                              {/* {"Client -" + item.user.firstName} */}
                              {item.user.firstName}{" "}
                              {props?.chatCountAdmin?.filter(
                                (c) => c.id === item.userId
                              )[0]?.isCount ? (
                                <div className="h-2 w-2 bg-progress rounded-full ml-1"></div>
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                      </nav>

                      {textTabName === "comment" && (
                        <Comments
                          data={comments}
                          projectStatus={projectStatus}
                          role={props.role}
                          loggedInUserId={nloggedInUserId}
                          users={commentChatUsers}
                          projectId={projectId}
                          loggedInUserName={props.loggedInUserName}
                          loggedInUserPhotoUrl={props.loggedInUserPhotoUrl}
                          handleCommentCount={props.handleCommentCount}
                          loggedInUserType={props.loggedInUserType}
                        />
                      )}

                      {textTabName === "support" && (
                        <Support
                          data={comments}
                          projectStatus={projectStatus}
                          role={props.role}
                          loggedInUserId={props.loggedInUserId}
                          users={supportChatUsers}
                          projectId={projectId}
                          loggedInUserName={props.loggedInUserName}
                          loggedInUserPhotoUrl={props.loggedInUserPhotoUrl}
                          handleSupportCount={props.handleSupportCount}
                          loggedInUserType={props.loginData.userTypeId}
                        />
                      )}

                      {textTabName !== "support" &&
                        textTabName !== "comment" && (
                          <ClientArtistLiveChat
                            data={textTabName}
                            projectId={projectId}
                            loggedInUserId={props.loggedInUserId}
                          />
                        )}
                    </div>
                    {/* </div> */}
                  </div>
                  {/* Right Panel */}
                  <div className="md:flex col-start-9 col-span-10 lg:col-start-9 lg:col-span-4 xl:col-start-9 xl:col-span-3 m-5 md:m-0">
                    <div className="flex flex-1 flex-row">
                      <div className="flex flex-1">
                        <div className="flex flex-col gap-0 w-full">
                          {!buttonShow && (
                            <Budget
                              data={budget}
                              projectStatus={projectStatus}
                              role={props.role}
                              loggedInUserId={props.loggedInUserId}
                              onSubmit={props.onSubmit}
                            />
                          )}

                          {buttonShow && (
                            <OfferPrice
                              data={acceptedArtist}
                              projectStatus={projectStatus}
                              role={props.role}
                              loggedInUserId={props.loggedInUserId}
                              onSubmit={props.onSubmit}
                              offerPriceVal={offerPriceVal}
                              isPaymentDone={isPaymentDone}
                              addDiscount={addDiscount}
                              projectId={projectId}
                            />
                          )}

                          {props.role !== USER_ROLE_CLIENT ? (
                            <ClientsInfo
                              data={clientsinfo}
                              gender={projectGender}
                              clientId={clientId}
                              role={props.role}
                            />
                          ) : (
                            ""
                          )}

                          {projectStatus !== "Declined Project" &&
                            projectStatus !== "Withdrawn Project" && (
                              <RegularDates
                                data={regularDates}
                                projectStatus={projectStatus}
                                role={props.role}
                                loggedInUserId={props.loggedInUserId}
                                onSubmit={props.onSubmit}
                              />
                            )}

                          {projectStatus === "Accepted Project by Artist" ||
                          projectStatus === "Accepted Project by Admin" ||
                          projectStatus === "Counter Offer by Artist" ||
                          projectStatus === "Counter Offer by Admin" ? (
                            <OfferPrice
                              data={acceptedArtist}
                              projectStatus={projectStatus}
                              role={props.role}
                              loggedInUserId={props.loggedInUserId}
                              onSubmit={props.onSubmit}
                              offerPriceVal={offerPriceVal}
                              isPaymentDone={isPaymentDone}
                              addDiscount={addDiscount}
                              budget={budget}
                            />
                          ) : (
                            ""
                          )}
                          <Buttons
                            data={regularDates}
                            budget={budget}
                            counterOfferData={acceptedArtist}
                            isInMarketPlace={isInMarketPlace}
                            projectStatus={projectStatus}
                            role={props.role}
                            loggedInUserId={props.loggedInUserId}
                            onSubmit={props.onSubmit}
                            projectId={projectId}
                            pClientId={clientId}
                          />
                          {projectStatus !== "New Project" &&
                            projectStatus !== "Declined Project" &&
                            projectStatus !== "Withdrawn Project" &&
                            props.role !== "PSArtist" && (
                              <ArtistInfo
                                data={acceptedArtist}
                                role={props.role}
                                loggedInUserId={props.loggedInUserId}
                                onSubmit={props.onSubmit}
                                projectStatus={projectStatus}
                                projectStatusId={projectStatusId}
                                projectId={projectId}
                                isPaymentDone={isPaymentDone}
                                onTempSubmit={props.onTempSubmit}
                                clientProposedDates={regularDates}
                              />
                            )}
                          <StyledRating
                            data={ratings}
                            projectStatus={projectStatus}
                            role={props.role}
                            onSubmit={props.onSubmit}
                            artistId={artistId}
                            loggedInUserId={props.loggedInUserId}
                          />
                          {!HIDE_DISABLE_FLAG &&
                            adShow &&
                            !buttonShow &&
                            ![1, 3, 4].includes(projectStatusId) && (
                              <div className="flex flex-col gap-6 mt-5">
                                {marketVisibility && !isInMarketPlace && (
                                  <div
                                    className="text-sm text-primary"
                                    onClick={(e) => {
                                      moveToMarket();
                                    }}
                                  >
                                    Move to Market Place
                                  </div>
                                )}
                              </div>
                            )}

                          {adShow &&
                            !buttonShow &&
                            ![3, 4].includes(projectStatusId) && (
                              <div className="flex flex-col gap-6 mt-5">
                                <div className="pt-1 w-full border-tertiary mb-0 opacity-20" />
                                <div
                                  className="text-base text-primary flex flex-row"
                                  onClick={handleDeclineOpen}
                                >
                                  <Withdraw className="mr-2" />
                                  Decline Project
                                </div>
                                {declineOpen && (
                                  <DeclineProject
                                    open={declineOpen}
                                    setOpen={setDeclineOpen}
                                    loggedInUserId={props.loggedInUserId}
                                    onSubmit={props.onSubmit}
                                  />
                                )}
                              </div>
                            )}

                          {cShow &&
                            !buttonShow &&
                            ![4].includes(projectStatusId) && (
                              <div className="flex flex-row w-full mt-5">
                                {withdrawVisibility &&
                                projectStatus != "Withdrawn Project" ? (
                                  <div className="w-full">
                                    <div className="pt-1 w-full border-b border-tertiary mb-5 opacity-20" />
                                    <div
                                      className="text-base text-primary flex flex-row"
                                      onClick={(e) => {
                                        confirmWithdrawProject();
                                      }}
                                    >
                                      <Withdraw className="mr-2" />
                                      Withdraw Project
                                    </div>
                                  </div>
                                ) : (
                                  // <div
                                  //   className="flex bg-primary rounded-3xl text-white font-semibold w-full py-2 justify-center"
                                  //   onClick={(e) => {
                                  //     recreateProject();
                                  //   }}
                                  // >
                                  //   {" "}
                                  //   Recreate Project
                                  // </div>
                                  <></>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {errorMsg}{" "}
                </main>
              )}
              {/* <SessionExpiry sessionError={props.projectError} /> */}
              {props.showProgress && (
                <div className="h-full w-full flex flex-1 justify-center items-center">
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetailsView;

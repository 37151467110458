/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resendVerificationMail } from "../../../redux/actions/UserActions";
import { useHistory } from "react-router-dom";

const ResendVerificationMail = (props) => {
  const email = props.match.params.id;
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    onSubmit();
  }, [email]);

  const onSubmit = () => {
    let obj = {
      email: email,
    };
    dispatch(resendVerificationMail(obj));
    history.push("/auth/login");
  };

  return <></>;
};

export default ResendVerificationMail;

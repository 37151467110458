/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import localStorageService from "services/localStorageService";
import { Link } from "react-router-dom";
import { socket } from "utils/socketContext";
import { useDispatch } from "react-redux";
import { getUnReadNotications } from "redux/actions/NotificationAction";
import {
  NOTIFICATION_TYPE_NEW_PROJECT,
  NOTIFICATION_TYPE_NEW_ARTIST,
  NOTIFICATION_TYPE_NEW_REVIEW,
  NOTIFICATION_TYPE_NEW_SUPPORT_MESSAGE,
  NOTIFICATION_TYPE_NEW_CLIENT,
} from "../../layouts/AdminConstants";
import { markNotificationsAsReadforUserAndType } from "redux/actions/NotificationAction";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideNavBar(props) {
  const bg = props.bg ? props.bg : "white";
  const [newProjectCount, setNewProjectCount] = useState(
    localStorageService
      .getItem("notification")
      .filter((item) => item.type === NOTIFICATION_TYPE_NEW_PROJECT).length
  );
  const [newArtistCount, setNewArtistCount] = useState(
    localStorageService
      .getItem("notification")
      .filter((item) => item.type === NOTIFICATION_TYPE_NEW_ARTIST).length
  );
  const [newReviewCount, setNewReviewCount] = useState(
    localStorageService
      .getItem("notification")
      .filter((item) => item.type === NOTIFICATION_TYPE_NEW_REVIEW).length
  );
  const [newSupportMsgCount, setNewSupportMsgCount] = useState(
    localStorageService
      .getItem("notification")
      .filter((item) => item.type === NOTIFICATION_TYPE_NEW_SUPPORT_MESSAGE)
      .length
  );
  const [newClientCount, setNewClientCount] = useState(
    localStorageService
      .getItem("notification")
      .filter((item) => item.type === NOTIFICATION_TYPE_NEW_CLIENT).length
  );

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      socket.on("projectCreateNotificationToAdmin", (isTrue) => {
        if (isTrue) {
          dispatch(getUnReadNotications(props.loginID)).then((res1) => {
            if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
              localStorageService.setItem(
                "notification",
                res1?.payload?.notification
              );
              setNewProjectCount(
                localStorageService
                  .getItem("notification")
                  .filter((item) => item.type === NOTIFICATION_TYPE_NEW_PROJECT)
                  .length
              );
            }
          });
        }
      });

      socket.on("reviewCreateNotificationToAdmin", (isTrue) => {
        if (isTrue) {
          dispatch(getUnReadNotications(props.loginID)).then((res1) => {
            if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
              localStorageService.setItem(
                "notification",
                res1?.payload?.notification
              );
              setNewReviewCount(
                localStorageService
                  .getItem("notification")
                  .filter((item) => item.type === NOTIFICATION_TYPE_NEW_REVIEW)
                  .length
              );
            }
          });
        }
      });

      socket.on("artistCreateNotificationToAdmin", (isTrue) => {
        if (isTrue) {
          dispatch(getUnReadNotications(props.loginID)).then((res1) => {
            if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
              localStorageService.setItem(
                "notification",
                res1?.payload?.notification
              );
              setNewArtistCount(
                localStorageService
                  .getItem("notification")
                  .filter((item) => item.type === NOTIFICATION_TYPE_NEW_ARTIST)
                  .length
              );
            }
          });
        }
      });

      socket.on("newSupportMessageNotificationToAdmin", (isTrue) => {
        if (isTrue) {
          dispatch(getUnReadNotications(props.loginID)).then((res1) => {
            if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
              localStorageService.setItem(
                "notification",
                res1?.payload?.notification
              );
              setNewSupportMsgCount(
                localStorageService
                  .getItem("notification")
                  .filter(
                    (item) =>
                      item.type === NOTIFICATION_TYPE_NEW_SUPPORT_MESSAGE
                  ).length
              );
            }
          });
        }
      });

      socket.on("clientCreateNotificationToAdmin", (isTrue) => {
        if (isTrue) {
          dispatch(getUnReadNotications(props.loginID)).then((res1) => {
            if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
              localStorageService.setItem(
                "notification",
                res1?.payload?.notification
              );
              setNewClientCount(
                localStorageService
                  .getItem("notification")
                  .filter((item) => item.type === NOTIFICATION_TYPE_NEW_CLIENT)
                  .length
              );
            }
          });
        }
      });
    } catch (error) {
      console.log("Side bar Notification error", error);
    }
  }, [socket]);

  const updateDataDetails = (id, type) => {
    updateNotificationStatus(type);
    localStorageService.setItem("side_item", id);
  };

  const updateNotificationStatus = (type) => {
    dispatch(markNotificationsAsReadforUserAndType(props.loginID, type)).then(
      (res) => {
        if (res && res.type === "NOTIFICATION_USERTYPE_SUCCESS") {
          dispatch(getUnReadNotications(props.loginID)).then((res1) => {
            if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
              localStorageService.setItem(
                "notification",
                res1?.payload?.notification
              );
              let allNotifications =
                localStorageService.getItem("notification");
              setNewArtistCount(
                allNotifications.filter(
                  (item) => item.type === NOTIFICATION_TYPE_NEW_ARTIST
                ).length
              );
              setNewReviewCount(
                allNotifications.filter(
                  (item) => item.type === NOTIFICATION_TYPE_NEW_REVIEW
                ).length
              );
              setNewProjectCount(
                allNotifications.filter(
                  (item) => item.type === NOTIFICATION_TYPE_NEW_PROJECT
                ).length
              );
              setNewSupportMsgCount(
                allNotifications.filter(
                  (item) => item.type === NOTIFICATION_TYPE_NEW_SUPPORT_MESSAGE
                ).length
              );
              setNewClientCount(
                allNotifications.filter(
                  (item) => item.type === NOTIFICATION_TYPE_NEW_CLIENT
                ).length
              );
            }
          });
        }
      }
    );
  };

  return (
    <>
      {/* Narrow sidebar */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col">
          <div
            className={`flex-1 flex flex-col min-h-0 overflow-y-auto bg-${bg} bg-opacity-1`}
          >
            <div className="flex-1">
              <nav
                aria-label="Sidebar"
                className="py-2 flex flex-col items-start space-y-3"
              >
                {props.navigations.length !== 0 &&
                  props.navigations.map((item) => (
                    <div
                      key={item.id}
                      className={item.isDisabled ? `sm:hidden` : ""}
                    >
                      {
                        (item.current =
                          item.id ===
                          localStorageService.getItem("side_item", "1"))
                      }
                      <a
                        key={item.id}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "border-r-3 border-primary"
                            : "border-r-0",
                          "flex items-center p-4 font-bold inline-block relative "
                        )}
                        onClick={() => updateDataDetails(item.id, item.type)}
                      >
                        <item.icon
                          src={item.icon}
                          className={classNames(
                            item.current
                              ? "text-primary border-primary color-primary"
                              : "text-tertiary hover:text-primary",
                            "mr-0 h-8 w-8"
                          )}
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          title={item.name}
                        />
                        {newProjectCount > 0 &&
                          item.type === NOTIFICATION_TYPE_NEW_PROJECT && (
                            <p className="absolute top-2 right-2 block h-5 w-5 rounded-full ring-2 ring-white bg-red text-white font-sans font-semibold text-xs flex items-center justify-center text-ellipsis overflow-hidden">
                              {newProjectCount}
                            </p>
                          )}

                        {newArtistCount > 0 &&
                          item.type === NOTIFICATION_TYPE_NEW_ARTIST && (
                            <p className="absolute top-2 right-2 block h-5 w-5 rounded-full ring-2 ring-white bg-red text-white font-sans font-semibold text-xs flex items-center justify-center text-ellipsis overflow-hidden">
                              {newArtistCount}
                            </p>
                          )}

                        {newReviewCount > 0 &&
                          item.type === NOTIFICATION_TYPE_NEW_REVIEW && (
                            <p className="absolute top-2 right-2 block h-5 w-5 rounded-full ring-2 ring-white bg-red text-white font-sans font-semibold text-xs flex items-center justify-center text-ellipsis overflow-hidden">
                              {newReviewCount}
                            </p>
                          )}
                        {newSupportMsgCount > 0 &&
                          item.type ===
                            NOTIFICATION_TYPE_NEW_SUPPORT_MESSAGE && (
                            <p className="absolute top-2 right-2 block h-5 w-5 rounded-full ring-2 ring-white bg-red text-white font-sans font-semibold text-xs flex items-center justify-center text-ellipsis overflow-hidden">
                              {newSupportMsgCount}
                            </p>
                          )}
                        {newClientCount > 0 &&
                          item.type === NOTIFICATION_TYPE_NEW_CLIENT && (
                            <p className="absolute top-2 right-2 block h-5 w-5 rounded-full ring-2 ring-white bg-red text-white font-sans font-semibold text-xs flex items-center justify-center text-ellipsis overflow-hidden">
                              {newClientCount}
                            </p>
                          )}
                        {/*className="sr-only"*/}
                        {/* <span>{item.name}</span>  */}
                      </a>
                    </div>
                  ))}
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={props.mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={() => props.handleMenu(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col ">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-1 right-0 -mr-14 p-1">
                  <button
                    type="button"
                    className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => props.handleMenu(!props.mobileMenuOpen)}
                  >
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    <span className="sr-only">Close sidebar</span>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 px-4 flex items-center">
                <Link to="/">
                  <object
                    type="image/svg+xml"
                    data="/2ndSkin.svg"
                    className="pointer-events-none h-20"
                  >
                    2ndSkin
                  </object>
                </Link>
              </div>
              <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                <nav className="h-full flex flex-col">
                  <div className="space-y-1">
                    {props.navigations.length !== 0 &&
                      props.navigations.map((item) => (
                        <div key={item.id}>
                          {
                            (item.current =
                              item.id ===
                              localStorageService.getItem("side_item", "1"))
                          }
                          <a
                            key={item.id}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "border-r-3 border-primary"
                                : "border-r-0",
                              "group py-2 px-2 flex items-center text-sm font-normal"
                            )}
                            aria-current={item.current ? "page" : undefined}
                            onClick={() =>
                              localStorageService.setItem("side_item", item.id)
                            }
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-primary border-primary color-primary"
                                  : "text-tertiary hover:text-primary",
                                "mr-3 h-7 w-7"
                              )}
                              aria-hidden="true"
                            />
                            <span
                              className={classNames(
                                item.current
                                  ? "text-primary color-primary"
                                  : "text-tertiary group-hover:text-tertiary",
                                "text-xl font-sans"
                              )}
                            >
                              {item.name}
                            </span>
                          </a>
                        </div>
                      ))}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

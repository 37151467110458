/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ProjectCreateView from "./ProjectCreateView";
import { getStyles } from "../../../redux/actions/StyleAction";
import { getTechniques } from "../../../redux/actions/TechniqueAction";
import { getSkinTones } from "../../../redux/actions/SkinToneAction";
import { getServices } from "../../../redux/actions/ServiceAction";
import { getColors } from "../../../redux/actions/ColorAction";
import { useDispatch, useSelector } from "react-redux";
import { sections, projectRequestKeys } from "../CONSTANTS";
import {
  signUp,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
} from "../../../redux/actions/UserActions";
import { createProject } from "../../../redux/actions/ProjectAction";
import { useHistory } from "react-router-dom";
import { MAKE_TATOO_THANK } from "navigation/CONSTANTS";
import { RouterPrompt } from "utils/RouterPrompt";
import Swal from "sweetalert2";

const ProjectCreateContainer = (props) => {
  const colorDataObj = useSelector((state) => state.color.data);
  const styleDataObj = useSelector((state) => state.style.stylesData);
  const serviceDataObj = useSelector((state) => state.service.data);
  const techniqueDataObj = useSelector((state) => state.technique.data);
  const skinToneDataObj = useSelector((state) => state.skintone.data);
  const [storedProjectState, setStoredProjectState] = useState({});
  const [selectedSection, setSelectedSection] = useState(
    sections.UploadPictures
  );
  const [uploadedImages, setUploadedImages] = useState([]);
  const loggedInUserId = props?.loginData?.id;
  let history = useHistory();
  const dispatch = useDispatch();
  let fisrtTime = true;
  const [error, setErrorState] = useState();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [storedRegisterPageState, setStoredRegisterPageState] = useState({});

  useEffect(() => {
    if (fisrtTime) {
      dispatch(getStyles());
      dispatch(getServices());
      dispatch(getTechniques());
      dispatch(getColors());
      dispatch(getSkinTones());
      fisrtTime = false;
      setShouldBlockNavigation(false);
    } else {
      setShouldBlockNavigation(true);
    }
  }, []);

  useEffect(() => {
    setShouldBlockNavigation(true);
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [history]);

  const onBack = (backToSection) => {
    setSelectedSection(backToSection);
  };

  const showAlert = (text) => {
    return Swal.fire({
      icon: "warning",
      title: "",
      text: "All fields are mandatory.",
      confirmButtonText: "OK",
      showCancelButton: false,
      cancelButtonText: "No",
    });
  };

  const onSubmit = (obj, a, formname) => {
    var tempProjectState;

    if (obj instanceof FormData) {
      tempProjectState = Object.assign(storedProjectState, { [formname]: obj });
    } else {
      tempProjectState = Object.assign(storedProjectState);
    }

    setSelectedSection(a);
    if (a === sections.Submit) {
      setShouldBlockNavigation(false);
      let errorArr = [];
      let projectObj = new FormData();
      Object.keys(storedProjectState).map((key) => {
        for (var pair of storedProjectState[key].entries()) {
          if (
            pair[0] !== "modelPictureWithPoints" &&
            pair[0] !== "modelPictureOriginal" &&
            pair[0] !== "dimensions"
          ) {
            projectObj.append(pair[0], pair[1]);
            errorArr.push(pair[0]);
          }
        }
      });

      
      if (errorArr.length < projectRequestKeys.length) {
        showAlert();
        setSelectedSection(sections.UploadPictures);
        return;
      }

      if (loggedInUserId) {
        //user is logged in       
        projectObj.append("clientId", loggedInUserId);
        projectObj.append("statusId", 1);  

        dispatch(createProject(projectObj))
          .then(() => history.push("/client/thankyou"))
          .catch((err) => {
            setErrorState("Error Occured! " + err);
          });
      } else {
        // new user creating project  
        dispatch(signUp(obj))
          .then((res) => {
            if (res.type && res.type === SIGNUP_SUCCESS) {              
              projectObj.append("clientId", res.payload.id);
              projectObj.append("statusId", 1);

              dispatch(createProject(projectObj));
              history.push(MAKE_TATOO_THANK);
            } else if (res.type && res.type === SIGNUP_ERROR) {
              setErrorState(res.payload.data.error.message);
            } else {
              setErrorState("Error Occured! " + res);
            }
          })
          .catch((err) => {
            setErrorState("Error Occured! " + err);
          });
      }
    }
  };
  return (
    <div>
      {/* Important: <prompt> will work only in react-dom-router 5. Higher vesrsion doesnot support it. */}
      <RouterPrompt
        when={shouldBlockNavigation}
        title="You will loose the project data if you leave. Continue?"
        cancelText="Cancel"
        okText="Confirm"
        onOK={() => true}
        onCancel={() => false}
      />
      <ProjectCreateView
        error={error}
        onSubmit={onSubmit}
        onBack={onBack}
        colorDataObj={colorDataObj}
        styleDataObj={styleDataObj}
        serviceDataObj={serviceDataObj}
        techniqueDataObj={techniqueDataObj}
        skinToneDataObj={skinToneDataObj}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        loggedInUserId={loggedInUserId}
        uploadedImages={uploadedImages}
        setUploadedImages={setUploadedImages}
        title="Project Create View"
        loginData={props?.loginData}
        storedProjectState={storedProjectState}
        setStoredProjectState={setStoredProjectState}
        setShouldBlockNavigation={setShouldBlockNavigation}       
        storedRegisterPageState={storedRegisterPageState} 
        setStoredRegisterPageState={setStoredRegisterPageState}
      />
    </div>
  );
};

export default ProjectCreateContainer;

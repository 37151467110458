/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import CheckBox from "components/UI/CheckBox";
import { sections } from "../../CONSTANTS";
import {
  defaultMapProps,
  USER_ROLE_CLIENT,
  USER_TYPE_CLIENT,
} from "layouts/AdminConstants";
import { useHistory } from "react-router-dom";
import { getCountries } from "redux/actions/CountryAction";
import Dropdown from "views/landing/support/components/Dropdown";
import Country from "components/UI/Country";
import CryptoJS from "crypto-js";
import { SCERETPASS } from "utils/constants";

const registerValidationSchema = yup.object({
  firstName: yup
    .string("Enter your First Name")
    .required("First Name is required")
    .test("alphabets", "First Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  lastName: yup
    .string("Enter your Last Name")
    .required("Last Name is required")
    .test("alphabets", "Last Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  address: yup
    .string("Enter your Address")
    .max(100, ({ max }) => "Address can be of maximum 100 characters long")
    .required("Address is required"),
  birthYear: yup
    .number()
    .positive()
    .integer()
    .required(`Birth Year is Required`)
    .typeError('Year must be a four digit number')
    // .matches(/(\d){4}\b/, "Enter a valid birth year")
    .min(1930, "Enter a valid birth year")
    .max(2020, "Enter a valid birth year"),
  phone: yup
    .string()
    .matches(/(\d){9}\b/, "Enter a valid mobile number")
    .max(10, "Enter a valid mobile number")
    .required(`Phone Number is required`),
  zipcode: yup
    .string()
    .matches(/^[0-9]*$/, "Zip Code should be a number")
    .required(`Zip Code is required`),
  city: yup
    .string("Enter your city")
    .required("City is required")
    .test("alphabets", "City must contain valid characters", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  country: yup.string().required("Country is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .max(16, "Password should be of maximum 16 characters length")
    .matches(
      /^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
      "Must Contain One uppercase, One lowercase, One number and One special case Character"
    )
    .required("Password is required"),
  confirmPassword: yup
    .string("Enter your password")
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Confirm Password is required"),
  terms: yup
    .boolean()
    .default(false)
    .required("You must accept the terms and conditions")
    .oneOf([true], "You must accept the terms and conditions"),
});

export default function RegisterPage(props) {
  const [firstTime, setFirstTime] = useState(false);
  const countries = useSelector((state) => state.country.data);
  const [countryOptions, setCountryOptions] = useState([]);
  const [latitude, setLatitude] = useState(defaultMapProps.center.lat);
  const [longitude, setLongitude] = useState(defaultMapProps.center.lng);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const history = useHistory();
  const [showTerms, setShowTerms] = useState(false); 
  const [formValues, setFormValues] = useState({
    email: props?.storedRegisterPageState?.email ?? "",
    password: "",
    confirmPassword: "",
    firstName: props?.storedRegisterPageState?.firstName ?? "",
    lastName: props?.storedRegisterPageState?.lastName ?? "",
    address: props?.storedRegisterPageState?.street ?? "",
    city: props?.storedRegisterPageState?.city ?? "",
    country: props?.storedRegisterPageState?.country ?? "Switzerland",
    zipcode: props?.storedRegisterPageState?.zipcode ?? "",
    phone: props?.storedRegisterPageState?.phone ?? "",
    birthYear: props?.storedRegisterPageState?.birthYear ?? "",
    terms: props?.storedRegisterPageState?.terms ?? false,
    phoneCode: props?.storedRegisterPageState?.phoneCode ?? "+41",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!firstTime) {
      dispatch(getCountries());
      setFirstTime(true);
    }
  }, []);

  useEffect(() => {
    populateCountry();
    populatePhoneCode();
  }, [countries]);

  useEffect(() => {
    if (showTerms) {
      props.setRegisterTitle("Terms of Services");
    } else {
      props.setRegisterTitle("Register");
    }
  }, [showTerms]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showTerms])

  const handleFormSubmit = (values) => {
    var encrypted = CryptoJS.AES.encrypt(values.password, SCERETPASS).toString();
 
    let obj = {
      firstName: values.firstName,
      lastName: values.lastName,
      birthYear: values.birthYear,
      street: values.address,
      city: values.city,
      zipcode: values.zipcode,
      country: values.country,
      phone: values.phone,
      email: values.email.toLowerCase().replace(/\s/g, ""),
      password: encrypted,
      userTypeId: USER_TYPE_CLIENT,
      userRoleId: USER_ROLE_CLIENT,
      isWhatsappNotification: 0,
      subcription: 0,
      photo_url: "",
      latitude: latitude,
      longitude: longitude,
      phoneCode: values.phoneCode,
    };
    props.setStoredRegisterPageState(obj);
    props.onSubmit(obj, sections.Submit);
  };

  const login = () => {
    props.setSelectedSection(sections.Login);
  };

  const populateCountry = () => {
    let countryOptionItems = [];
    if (countries && countries.length > 0) {
      countryOptionItems = countries.map((country) => (
        <option key={country.id} value={country.id}>
          {country.name}
        </option>
      ));
    }
    setCountryOptions(countryOptionItems);
  };

  const populatePhoneCode = () => {
    let phoneCodeOptionItems = [];
    if (countries && countries.length > 0) {
      phoneCodeOptionItems = countries.map((country) => (
        <option key={country.phoneCode} value={country.phoneCode}>
          {country.phoneCode}
        </option>
      ));
    }
    setPhoneCodeOptions(phoneCodeOptionItems);
  };

  const saveTempValues = () => {
    setFormValues({
      email: formikRegister.values.email,
      password: formikRegister.values.password,
      confirmPassword: formikRegister.values.password,
      firstName: formikRegister.values.firstName,
      lastName: formikRegister.values.lastName,
      address: formikRegister.values.address,
      city: formikRegister.values.city,
      country: formikRegister.values.country,
      zipcode: formikRegister.values.zipcode,
      phone: formikRegister.values.phone,
      birthYear: formikRegister.values.birthYear,
      terms: false,
      phoneCode: formikRegister.values.phoneCode,
    });
  };

  const formikRegister = useFormik({
    initialValues: {
      email: formValues.email,
      password: formValues.password,
      confirmPassword: formValues.password,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      address: formValues.address,
      city: formValues.city,
      country: formValues.country,
      zipcode: formValues.zipcode,
      phone: formValues.phone,
      birthYear: formValues.birthYear,
      terms: false,
      phoneCode: formValues.phoneCode,
    },
    validationSchema: registerValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  return (
    <>
      {showTerms && (
        <div className="grid grid-cols-2 mb-10 overflow-y-scroll">
          <div className="flex mt-2 col-start-1 col-span-2 mb-5">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy text ever since the 1500s, when
            an unknown printer took a galley of type and scrambled it to make a
            type specimen book. It has survived not only five centuries, but
            also the leap into electronic typesetting, remaining essentially
            unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently
            with desktop publishing software like Aldus PageMaker including
            versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the
            leap into electronic typesetting, remaining essentially unchanged.
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy text ever since the 1500s, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic
            typesetting, remaining essentially unchanged. It was popularised in
            the 1960s with the release of Letraset sheets containing Lorem Ipsum
            passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
          </div>
          <Button
            label="Back"
            textsize="base"
            onClick={() => setShowTerms(false)}
            background="white"
            bordercolor="primary"
            color="primary"
          />
        </div>
      )}
      {!showTerms && (
        <div className="md:grid grid-cols-8">
          <div className="col-start-1 col-span-7">
            <section
              className="bg-white pb-12"
              aria-labelledby="register-heading"
            >
              <div className="">
                <form
                  onSubmit={formikRegister.handleSubmit}
                  className="md:gap-y-2 md:grid grid-cols-2 sm:gap-x-8"
                >
                  <div>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.firstName}
                      label="First Name"
                      autoComplete="username"
                    />
                    <p className="text-red">
                      {formikRegister.errors.firstName &&
                        formikRegister.touched.firstName &&
                        formikRegister.errors.firstName}
                    </p>
                  </div>

                  <div>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      label="Last Name"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.lastName}
                      autoComplete="username"
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />

                    <p className="text-red">
                      {formikRegister.errors.lastName &&
                        formikRegister.touched.lastName &&
                        formikRegister.errors.lastName}
                    </p>
                  </div>

                  <div>
                    <Input
                      type="text"
                      name="birthYear"
                      id="birthYear"
                      label="Year of Birth"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.birthYear}
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />
                    <p className="text-red">
                      {formikRegister.errors.birthYear &&
                        formikRegister.touched.birthYear &&
                        formikRegister.errors.birthYear}
                    </p>
                  </div>
                  <div className=""></div>
                  <div>
                        <div className="border border-primary rounded-2xl mt-4 py-2 px-3 shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary">
                          <label
                            htmlFor="address"
                            className="block text-xs font-medium text-primary font-sans"
                          >Address</label>
                          <div className={`flex flex-row`}>
                            <textarea
                                            type="text"
                                            name="address"
                                            id="address"
                                            label="Address"
                                            onChange={formikRegister.handleChange}
                                            onBlur={formikRegister.handleBlur}
                                            value={formikRegister.values.address}
                                            rows={1}
                                            className="font-sans w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                                            />
                          </div>
                        </div>
                        <p className="text-red">
                          {formikRegister.errors.address &&
                        formikRegister.touched.address &&
                        formikRegister.errors.address}
                        </p>
                      </div>
                  {/* <div>
                    <Input
                      type="text"
                      name="address"
                      id="address"
                      label="Address"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.address}
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />

                    <p className="text-red">
                      {formikRegister.errors.address &&
                        formikRegister.touched.address &&
                        formikRegister.errors.address}
                    </p>
                  </div> */}
                  <div>
                    <Input
                      type="text"
                      name="zipcode"
                      id="zipcode"
                      label="Zip Code"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.zipcode}
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />
                    <p className="text-red">
                      {formikRegister.errors.zipcode &&
                        formikRegister.touched.zipcode &&
                        formikRegister.errors.zipcode}
                    </p>
                  </div>
                  <div>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      label="City"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.city}
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />

                    <p className="text-red">
                      {formikRegister.errors.city &&
                        formikRegister.touched.city &&
                        formikRegister.errors.city}
                    </p>
                  </div>
                  <div>
                    <Dropdown label="Country">
                      <select
                        className="w-full bg-white border-transparent focus:outline-none"
                        id="country"
                        name="country"
                        value={formikRegister.values.country}
                        onChange={formikRegister.handleChange}
                        onBlur={formikRegister.handleBlur}
                      >
                        {countryOptions}
                      </select>
                    </Dropdown>
                    <p className="text-red font-sans">
                      {formikRegister.errors.country &&
                        formikRegister.touched.country &&
                        formikRegister.errors.country}
                    </p>
                  </div>
                  <div>
                    <Country
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      phonecode={formikRegister.values.phoneCode}
                      phonecodeoptions={phoneCodeOptions}
                      phone={formikRegister.values.phone}
                    />
                    <p className="text-red">
                      {formikRegister.errors.phone &&
                        formikRegister.touched.phone &&
                        formikRegister.errors.phone}
                    </p>
                  </div>
                  <div>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      label="Email"
                      autoComplete="username"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.email}
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />
                    <p className="text-red">
                      {formikRegister.errors.email &&
                        formikRegister.touched.email &&
                        formikRegister.errors.email}
                    </p>
                  </div>
                  <div>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      label="Password"
                      autoComplete="new-password"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.password}
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />
                    <p className="text-red">
                      {formikRegister.errors.password &&
                        formikRegister.touched.password &&
                        formikRegister.errors.password}
                    </p>
                  </div>
                  <div>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      label="Confirm Password"
                      autoComplete="new-password"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.confirmPassword}
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />

                    <p className="text-red">
                      {formikRegister.errors.confirmPassword &&
                        formikRegister.touched.confirmPassword &&
                        formikRegister.errors.confirmPassword}
                    </p>
                  </div>
                  <div className="my-4">
                    <CheckBox
                      id="terms"
                      name="terms"
                      type="checkbox"
                      label="Terms of Service"
                      value={formikRegister.values.terms}
                      onChange={formikRegister.handleChange}
                      labelshowaslink={+true}
                      onLabelClick={() => {
                        saveTempValues();
                        setShowTerms(true);
                      }}
                    />
                    <p className="text-red">
                      {formikRegister.errors.terms &&
                        formikRegister.touched.terms &&
                        formikRegister.errors.terms}
                    </p>
                  </div>
                  <div className=""> </div>
                  <div className="sm:col-span-1 sm:justify-start sm:px-4 mb-10 md:mb-0 flex flex-col">
                    <div className="flex space-x-2">
                      <Button
                        label="Back"
                        textsize="base"
                        onClick={() => props.onBack(sections.Location)}
                        background="white"
                        bordercolor="primary"
                        color="primary"
                      />
                      <Button
                        type="submit"
                        label="Register"
                        textsize="base"
                        bordercolor="primary"
                        hoverbg="white"
                        hovertext="primary"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="md:gap-y-12 md:grid grid-cols-2 sm:gap-x-8">
                <Button
                  onClick={() => login()}
                  label="Log in instead"
                  textsize="base"
                  border="none"
                  background="white"
                  color="primary"
                  shadow="shadow-none"
                  hoverbg="white"
                  hovertext="primary"
                />
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";
import MessageInput from "./MessageInput";
import Messages from "./Messages";


// let socket;

const CommentDetails = (props) => {
  let commentTicketId = props.selectedTicketId;

  if (props?.projectId && props?.socket) {
    let username = props.projectId + "#" + props.loggedInUserId + "#C";
    props.socket.emit("register", username);
  }

  let show = false;
  if (props?.projectStatus !== "Project Done") {
    show = true;
  }

  return (
    <div className="flex flex-col gap-4 w-full text-sm">
      {show && props?.socket && (
        <div className="chat-container">
          <Messages
            socket={props.socket}
            supportTicketId={commentTicketId}
            loggedInUserId={props.loggedInUserId}
            projectId={props.projectId}
            selectedTab={props.selectedTab}
            userSelected={props.userSelected}
            suffix={"#C"}
            setMsgCount={props.setMsgCount}
            msgCount={props.msgCount}
            isFileUploaded={props.isFileUploaded}
            setIsFileUploaded={props.setIsFileUploaded}
            selectedTabUserType={props?.selectedTabUserType}
            loggedInUserType={props?.loggedInUserType}
          />
          <MessageInput
            socket={props.socket}
            supportTicketId={commentTicketId}
            loggedInUserId={props.loggedInUserId}
            loggedInUserName={props.loggedInUserName}
            loggedInUserPhotoUrl={props.loggedInUserPhotoUrl}
            projectId={props.projectId}
            userSelected={props.userSelected}
            btnHide={true}
            suffix={"#C"}
            msgCount={props.msgCount}
            setIsFileUploaded={props.setIsFileUploaded}
            selectedTabUserType={props?.selectedTabUserType}
            loggedInUserType={props?.loggedInUserType}
          />
        </div>
      )}
    </div>
  );
};

export default CommentDetails;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MessageInput from "./MessageInput";
import Messages from "./Messages";
import { SOCKET_CONNECTION_URL_PORT } from "utils/constants";



const SupportDetails = (props) => {
  let supportTicketId = props.selectedTicketId;
  const [isRefreshRequired, setIsRefreshRequired] = useState(false);

  let show = true;

  useEffect(() => {
    let username = props.projectId + "#" + props.loggedInUserId + "#S";
    props.socket.emit("register", username);
  }, [props.socket, SOCKET_CONNECTION_URL_PORT]);
  
  return (
    <div className="flex flex-col gap-4 w-full text-sm">
      {show && props.socket && (
        <div className="chat-container">
          <Messages
            socket={props.socket}
            supportTicketId={supportTicketId}
            loggedInUserId={props.loggedInUserId}
            projectId={props.projectId}
            selectedTab={props.selectedTab}
            userSelected={props.userSelected}
            suffix={"#S"}
            setMsgCount={props.setMsgCount}
            msgCount={props.msgCount}
            isFileUploaded={props.isFileUploaded}
            setIsFileUploaded={props.setIsFileUploaded}
            setIsRefreshRequired={setIsRefreshRequired}
          />
          <MessageInput
            socket={props.socket}
            supportTicketId={supportTicketId}
            loggedInUserId={props.loggedInUserId}
            loggedInUserName={props.loggedInUserName}
            loggedInUserPhotoUrl={props.loggedInUserPhotoUrl}
            projectId={props.projectId}
            userSelected={props.userSelected}
            btnHide={props.loggedInUserType != 4}
            suffix={"#S"}
            msgCount={props.msgCount}
            setIsFileUploaded={props.setIsFileUploaded}
            isRefreshRequired={isRefreshRequired}
            setIsRefreshRequired={setIsRefreshRequired}
          />
        </div>
      )}
    </div>
  );
};

export default SupportDetails;

import React, { useCallback, useState } from "react";
import Button from "components/UI/Button";
import { sections } from "../../CONSTANTS";
import { defaultMapProps, MapIcon, MAPS_KEY } from "layouts/AdminConstants";
import { useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

export default function LocationPage(props) {
  const [latitude, setLatitude] = useState(defaultMapProps.center.lat);
  const [longitude, setLongitude] = useState(defaultMapProps.center.lng);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(defaultMapProps.zoom);
  const [center, setCenter] = useState(defaultMapProps.center);

  useEffect(() => {
    window.scrollTo(0, 0); // Page should scroll to top
  }, []);

  useEffect(() => {
    if (props?.storedProjectState?.locationForm) {
      setLatitude(
        parseFloat(props?.storedProjectState?.locationForm?.get("latitude"))
      );
      setLongitude(
        parseFloat(props?.storedProjectState?.locationForm?.get("longitude"))
      );
    }
  }, [props?.storedProjectState?.locationForm]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const handleFormSubmit = () => {
    let obj = new FormData();
    obj.append("latitude", latitude);
    obj.append("longitude", longitude);
    props.setStoredProjectState((storedProjectState) => ({
      ...storedProjectState,
      "locationForm": obj,
    }));

    if (props.loggedInUserId) {
      props.onSubmit(obj, sections.Submit, "locationForm"); // if user is logged in
    } else {
      props.onSubmit(obj, sections.Finish, "locationForm"); // if new user go to register page
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAPS_KEY,
  });

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMapDragEnd = (map) => {
    const newCenter = {
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng(),
    };

    setCenter(newCenter);
  };

  const handleZoomChanged = () => {
    if (map) {
      const newZoom = map.getZoom();
      setZoom(newZoom);
    }
  };

  return (
    <div className="h-screen w-full grid grid-cols-2 mt-5 auto-rows-fr">
      <div className="flex col-start-1 col-span-2 h-full">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            options={{
              streetViewControl: false,
              gestureHandling: "greedy",
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onDragEnd={() => handleMapDragEnd(map)}
            onZoomChanged={handleZoomChanged}
          >
            <Marker
              position={{
                lat: latitude,
                lng: longitude,
              }}
              draggable={true}
              onDragEnd={(event) => {
                setLatitude(event.latLng.lat());
                setLongitude(event.latLng.lng());
              }}
              icon={MapIcon}
            />
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
      <div className="col-start-1 col-span-2 md:col-start-1 md:col-span-1">
        <div className="px-4 py-4 flex space-x-2 h-auto">
          <Button
            label="Back"
            textsize="base"
            onClick={() => props.onBack(sections.DateProposal)}
            background="white"
            bordercolor="primary"
            color="primary"
          />
          <Button
            type="submit"
            label={props?.loggedInUserId ? "Submit" : "Next"}
            textsize="base"
            bordercolor="primary"
            onClick={() => handleFormSubmit()}
          />
        </div>
      </div>
    </div>
  );
}

import { Formik } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import CryptoJS from "crypto-js";
import { SCERETPASS } from "../../../utils/constants";
import { useHistory, Link } from "react-router-dom";

const changePwdValidationSchema = yup.object({
  newPassword: yup.string().required("This field is required"),
  confirmPassword: yup.string().when("newPassword", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf(
        [yup.ref("newPassword")],
        "New Password and Confirmed password should match."
      ),
  }),
});

const ChangePassword = (props) => {

  const handleFormSubmit = (values) => {     
    var encrypted = CryptoJS.AES.encrypt(values.newPassword, SCERETPASS).toString();
    
    let obj = {
      newPassword: encrypted
    };
    props.onSubmit(obj);
  };

  const history = useHistory();

  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-start m-10">
          <div className="mx-auto w-full max-w-lg">
            <div>
              <Link to="/">
                <object
                  type="image/svg+xml"
                  data="/2ndSkin.svg"
                  className="h-20 w-auto content-center mx-auto object-cover pointer-events-none"
                >
                  2ndSkin
                </object>
              </Link>
              <h2 className="flex mt-5 sm:pt-10 text-3.5xl font-bold text-primary font-sans justify-center">
                Change Password
              </h2>
            </div>

            {props.showSuccess && (
              <div className="mt-5 flex flex-col items-center">
                <div className="mb-5">Password changed Successfully</div>

                <Button
                  onClick={() =>
                    history.replace({
                      pathname: "/auth/login",
                    })
                  }
                  label="Proceed with Login"
                  textsize="base"
                  border="none"
                  background="white"
                  color="primary"
                  shadow="shadow-none"
                  hoverbg="white"
                  hovertext="primary"
                  font="normal"
                />
              </div>
            )}

            {!props.showSuccess && (
              <div className="mt-5">
                <div className="mt-5">
                  <Formik
                    initialValues={{
                      newPassword: "",
                      confirmPassword: "",
                    }}
                    validationSchema={changePwdValidationSchema}
                    validateOnMount={true}
                    onSubmit={(values) => {
                      handleFormSubmit(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      resetForm,
                    }) => (
                      <form onSubmit={handleSubmit} className="">
                        <div className="overflow-hidden">
                          <Input
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            label="New Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                          />
                          <p className="text-red font-sans">
                            {errors.newPassword}
                          </p>
                        </div>

                        <div className="overflow-hidden">
                          <Input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            label="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                          />
                          <p className="text-red font-sans">
                            {errors.confirmPassword}
                          </p>
                        </div>

                        <div className="flex items-center mt-6 space-x-2">
                          <Button
                            label="Cancel"
                            type="button"
                            textsize="base"
                            onClick={() => resetForm}
                            background="white"
                            bordercolor="primary"
                            color="primary"
                          />
                          <Button type="submit" textsize="base" label="Save" />
                        </div>
                        <div className="grid grid-cols-1 md:gap-y-12 sm:grid-cols-2 sm:gap-x-8 mt-4"></div>
                      </form>
                    )}
                  </Formik>
                </div>
                {props.error}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

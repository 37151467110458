/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Formik, Field, useFormik } from "formik";
import * as yup from "yup";
import Button from "components/UI/Button";
import CustomSelect from "components/CustomSelect";
import Dropzone from "react-dropzone";
import CustomThumbnail from "components/CustomThumbnail";
import "../../styles/image.css";
import { sections } from "../../CONSTANTS";
import { ReactComponent as ChatIcon } from "../../../../assets/ic_chat.svg";
import { ReactComponent as RemoveIcon } from "../../../../assets/ic_remove.svg";
import { ReactComponent as MaximiseIcon } from "../../../../assets/ic_maximise.svg";
import { Alert } from "@mui/material";
import ShowPicturePreview from "./ShowPicturePreview";
import { v4 as uuidv4 } from "uuid";

const uploadPictureValidationSchema = yup.object({});

export default function UploadPicturesPage(props) {
  const [file, setFile] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [color, setColor] = useState(undefined);

  const [styleOptions, setStyleOptions] = useState([]);
  const [techniqueOptions, setTechniqueOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState();

  const [showImageError, setShowImageError] = useState(false);
  const [imageError, setImageError] = useState("");
  const [editOpen, setEditOpen] = useState(false);

  const [colorError, setColorError] = useState(null);
  const [imageRequiredError, setImageRequiredError] = useState(null);

  let iniColor = undefined;
  let iniStyle = [];
  let iniTecni = [];
  let initattoPics = [];
  let iniComment = "";
  let iniImages = [];

  if (props?.storedProjectState?.uploadImageForm) {
    let sColor = props?.storedProjectState?.uploadImageForm?.get("color");
    iniColor = sColor ? parseInt(sColor) : undefined;
    let sStyle = props?.storedProjectState?.uploadImageForm?.get("styles");
    iniStyle = sStyle ? parseInt(sStyle) : [];
    let sTech = props?.storedProjectState?.uploadImageForm?.get("techniques");
    iniTecni = sTech ? parseInt(sTech) : [];

    for (var pair of props?.storedProjectState?.uploadImageForm.entries()) {
      if (pair[0] === "files") {
        initattoPics.push(pair[1]);
        iniImages.push(pair[1]);
      }
    }
  }

  useEffect(() => {
    setColor(iniColor);
  }, [iniColor]);

  useEffect(() => {
    if (props?.storedProjectState?.uploadImageForm) {
      let sComments =
        props?.storedProjectState?.uploadImageForm?.get("comments");
      let sCmt = sComments ? JSON.parse(sComments) : "";
      setComments(sCmt);
      let prevFiles = [];
      for (var pair of props?.storedProjectState?.uploadImageForm.entries()) {
        if (pair[0] === "files") {
          prevFiles.push(pair[1]);
        }
      }
      setFile(prevFiles);
    }
  }, [props?.storedProjectState?.uploadImageForm]);

  let intialFormValues = {
    color: iniColor,
    multiStyles: iniStyle,
    multiTechniques: iniTecni,
    service: "",
  };

  let intialImageForm = {
    tattoPics: initattoPics,
    comment: iniComment,
    images: iniImages,
  };

  const formikImage = useFormik({
    initialValues: intialImageForm,
    onSubmit: (values, actions) => {
      handleImageForm(values, actions);
    },
  });

  const handleImageForm = (values, actions) => {
    const newComments = [...comments];
    for (let i = 0; i < file.length; i++) {
      if (file[i].name == selectedItem.name) {
        newComments[i] = values.comment;
        break;
      }
    }
    setComments(newComments);
  };

  const handleFormSubmit = (values) => {
    if (file.length === 0) {
      setImageRequiredError(
        <Alert className="m-4 " severity="error">
          At least one image should be uploaded
        </Alert>
      );
      return;
    }

    if (color === undefined) {
      setColorError(
        <Alert className="m-4 " severity="error">
          At least one color should be choosen
        </Alert>
      );
      return;
    }

    let obj = new FormData();
    obj.append("comments", JSON.stringify(comments));
    if (color != undefined) {
      obj.append("color", color);
    }

    if (values.multiStyles) {
      obj.append("styles", JSON.stringify(values.multiStyles));
    }

    if (values.multiTechniques) {
      obj.append("techniques", JSON.stringify(values.multiTechniques));
    }

    file.map((file) => obj.append("files", file));
    props.setUploadedImages(file);
    props.setStoredProjectState((storedProjectState) => ({
      ...storedProjectState,
      "uploadImageForm": obj,
    }));

    props.onSubmit(obj, sections.PlaceYourTatto, "uploadImageForm");
  };

  const populatStyles = () => {
    let styles = props.styleObj;
    let styleOptionItems = [];
    if (styles && styles.length > 0) {
      styles.forEach(function (value, index) {
        styleOptionItems.push({
          label: value.styleName,
          value: value.id,
        });
      });
      setStyleOptions(styleOptionItems);
    }
  };

  const populateTechniques = () => {
    let techniques = props.techniqueObj;
    let techniqueOptionItems = [];
    if (techniques && techniques.length > 0) {
      techniques.forEach(function (value, index) {
        techniqueOptionItems.push({
          label: value.techniqueName,
          value: value.id,
        });
      });
      setTechniqueOptions(techniqueOptionItems);
    }
  };

  const populateColors = () => {
    let colors = props.colorObj;
    let colorItems;
    if (colors && colors.length > 0) {
      colorItems = colors.map((color, index) => (
        <label key={index} className="flex items-center">
          <input
            type="radio"
            name="section"
            value={color.id}
            defaultChecked={
              intialFormValues.color && intialFormValues.color === color.id
                ? true
                : false
            }
            // checked={(intialFormValues.color && intialFormValues.color === color.id) ? "checked" : null}
            className={`peer mr-1 form-radio text-primary focus:ring-0 focus:border-0`}
            id="section"
            onChange={(event) => {
              setColor(parseInt(event.target.value));
              setColorError(null);
            }}
          />
          <label className="text-secondary peer-checked:text-primary m-1">
            {color.colorName}
          </label>
        </label>
      ));
      setColorOptions(colorItems);
    }
  };

  useEffect(() => {
    populatStyles();
    populateColors();
    populateTechniques();
  }, [props.styleObj, props.techniqueObj, props.colorObj]);

  const fillcomments = (i) => {
    const cValue = comments[i];
    formikImage.setFieldValue("comment", cValue ? cValue : "");
  };

  const handleShowPicture = () => {
    setEditOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="grid xl:grid-cols-8">
      <div className="xl:col-span-7">
        <section className="mt-4 pb-12" aria-labelledby="gallery-heading">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8"></div>
          <div className="">
            <form
              onSubmit={formikImage.handleSubmit} // was commented
              className="md:gap-y-12 sm:grid-cols-2 sm:gap-x-8"
              encType="multipart/form-data"
              onChange={() => setImageRequiredError(null)}
            >
              <Dropzone
                onDrop={(acceptedFiles, fileRejections) => {
                  if (fileRejections.length > 0) {
                    fileRejections.forEach((file) => {
                      file.errors.forEach((err) => {
                        if (err.code === "file-too-large") {
                          setShowImageError(true);
                          setImageError(
                            `Error: File too Large, Select file less than 10 MB`
                          );
                          return;
                        }

                        if (err.code === "file-invalid-type") {
                          setShowImageError(true);
                          setImageError(`Error: ${err.message}`);
                          return;
                        }
                      });
                    });
                  } else {
                    if (file.length + acceptedFiles.length > 4) {
                      setShowImageError(true);
                      setImageError("Max 4 files can be uploaded.");
                      return;
                    }

                    const renamedFiles = acceptedFiles.map((file, index) => {
                      var re = /(?:\.([^.]+))?$/;
                      var ext = re.exec(file.name)[1];
                      var filename = `tattoo-${uuidv4()}.${ext}`;
                      const customFile = new File([file], filename, {
                        type: file.type,
                        lastModified: file.lastModified,
                      });

                      return customFile;
                    });

                    setShowImageError(false);
                    formikImage.setFieldValue(
                      "tattoPics",
                      formikImage.values.tattoPics.concat(renamedFiles)
                    );
                    setFile((prev) => [...prev, ...renamedFiles]);
                    formikImage.setFieldValue("images", renamedFiles);
                  }
                }}
                onDragEnter={() => setShowImageError(false)}
                onFileDialogCancel={() => setShowImageError(false)}
                accept="image/*"
                minSize={1024}
                maxSize={10485760}
                maxFiles={4}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragAccept,
                  isDragReject,
                }) => {
                  const additionalClass = isDragAccept
                    ? "accept"
                    : isDragReject
                    ? "reject"
                    : "";

                  return (
                    <div
                      {...getRootProps({
                        className: `dropzone ${additionalClass}`,
                      })}
                      className="flex flex-col border-primary border border-dashed p-10 rounded-2xl items-center stroke-2 mb-0"
                    >
                      <p className="font-sans text-primary text-xs mb-2">
                        Drag & Drop images here
                      </p>
                      <p className="font-sans text-primary text-xs  mb-2">
                        {" "}
                        Or
                      </p>

                      <input {...getInputProps()} />
                      <span>
                        <button
                          type="button"
                          className="bg-primary px-10 py-3 rounded-3xl text-white font-sans font-bold text-xs"
                        >
                          {" "}
                          Add Images
                        </button>
                      </span>
                      <p className="font-sans text-tertiary text-xs mt-2 mb-2">
                        {" "}
                        Max 4 files
                      </p>
                    </div>
                  );
                }}
              </Dropzone>
              <div className={!showImageError ? "hidden" : undefined}>
                <Alert
                  action={
                    <RemoveIcon onClick={() => setShowImageError(false)} />
                  }
                  severity="error"
                >
                  {imageError}
                </Alert>
              </div>
              <div
                className={`${
                  formikImage.values.tattoPics.length ? "h-42" : "h-4"
                } w-screen -mx-5 md:mx-0 md:w-full`}
              >
                <ul className="whitespace-nowrap overflow-x-scroll mx-5 md:mx-0 my-2">
                  {" "}
                  {formikImage.values.tattoPics.map((file, i) => (
                    <div
                      className={`inline-flex relative  ${
                        selectedItem && file.name == selectedItem.name
                          ? "border-primary"
                          : "border-white"
                      }  border-2 h-36 w-36 space-x-1`}
                      key={i}
                    >
                      <CustomThumbnail
                        file={file}
                        selectedItem={setSelectedItem}
                        onClick={() => {
                          fillcomments(i);
                        }}
                      />
                      <div className="absolute mt-2">
                        <ChatIcon
                          hidden={comments[i] ? false : true}
                          onClick={() => fillcomments(i)}
                        />
                      </div>
                      <div className="absolute top-1 right-1">
                        <RemoveIcon
                          onClick={() => {
                            var temp = formikImage.values.tattoPics.filter(
                              (item) => item.name !== file.name
                            );
                            formikImage.setFieldValue("images", temp);
                            formikImage.setFieldValue("tattoPics", temp);
                            const newComments = comments.filter(
                              (item, index) => index !== i
                            );
                            setComments(newComments);
                            formikImage.setFieldValue("comment", "");
                            setFile(temp);
                          }}
                        />
                      </div>
                      <div className="absolute right-1 bottom-1">
                        <MaximiseIcon onClick={() => handleShowPicture()} />
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
              <div>
                <textarea
                  name="comment"
                  onChange={formikImage.handleChange}
                  onBlur={formikImage.handleBlur}
                  className="border border-tertiary rounded-xl w-full flex p-2 text-tertiary text-sm font-sans"
                  placeholder="Click on image to comment"
                  rows={3}
                  value={formikImage.values.comment}
                  autoComplete="Comment"
                  disabled={!selectedItem}
                />

                <p className="text-red">
                  {formikImage.errors.comment &&
                    formikImage.touched.comment &&
                    formikImage.errors.comment}
                </p>
              </div>
              <button
                className="bg-primary disabled:bg-tertiary rounded-xl px-7 py-1 mt-5 text-white font-sans text-xs"
                type="submit"
                disabled={!selectedItem}
              >
                {" "}
                Save{" "}
              </button>
            </form>
            {imageRequiredError}
            <Formik
              initialValues={intialFormValues}
              validationSchema={uploadPictureValidationSchema}
              onSubmit={(values) => {
                handleFormSubmit(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div
                    id="color-radio-group"
                    className="mt-4 mb-2 pt-2 text-2xl font-normal text-primary font-sans"
                  >
                    Tattoo Color
                  </div>
                  <div
                    role="group"
                    aria-labelledby="color-radio-group"
                    className="lg:w-2/3 flex justify-between mb-5"
                  >
                    {colorOptions}
                  </div>
                  {colorError}
                  <div className="md:grid md:grid-cols-2 md:gap-10 lg:gap-20 mt-4">
                    <div className="w-full">
                      <label className="font-sans text-primary font-normal text-2xl block mb-5">
                        Select{" "}
                        <a
                          href="https://2ndskn.com/tattoo/tattoo-stilarten/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Style
                        </a>{" "}
                        <label className="font-sans text-primary font-normal text-lg">
                          (Optional)
                        </label>
                      </label>
                      <Field
                        className="custom-select"
                        name="multiStyles"
                        options={styleOptions}
                        component={CustomSelect}
                        placeholder="Select Styles"
                        isMulti={false}
                        isCancelable={true}
                      />
                    </div>
                    <div className="mt-5 md:mt-0 w-full">
                      <label className="font-sans text-primary font-normal text-2xl block mb-5">
                        <a
                          href="https://2ndskn.com/tattoo/tattoo-techniken/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Technique
                        </a>{" "}
                        <label className="font-sans text-primary font-normal text-lg">
                          (Optional)
                        </label>
                      </label>

                      <Field
                        className="custom-select"
                        name="multiTechniques"
                        options={techniqueOptions}
                        component={CustomSelect}
                        placeholder="Select Techniques"
                        isMulti={false}
                        isCancelable={true}
                      />
                    </div>
                  </div>
                  {/* Buttons */}
                  <div className="grid md:grid-cols-7">
                    <div className="col-start-1 xl:col-span-2 md:col-span-3 mt-8">
                      <Button type="submit" label="Next" textsize="base" />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </section>
      </div>
      {editOpen && (
        <ShowPicturePreview
          open={editOpen}
          setOpen={setEditOpen}
          loggedInUserId={props.loggedInUserId}
          pictures={formikImage.values.tattoPics}
          role={props?.role}
          projectStatus={props?.projectStatus}
          comments={comments}
        />
      )}
    </div>
  );
}

import UserService from "services/UserService";
import axios from "../../http-config";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const ADD_USER = "ADD_USER";
export const USER_CREATE_ERROR = "USER_CREATE_ERROR";
export const LOADING = "LOADING";

export const MANAGER_LOADING = "MANAGER_LOADING";
export const GET_MANAGER_SUCCESS = "GET_MANAGER_SUCCESS";
export const GET_MANAGER_ERROR = "GET_MANAGER_ERROR";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const UPDATE_PROFILE_PIC_LOADING = "UPDATE_PROFILE_PIC_LOADING";
export const UPDATE_PROFILE_PIC_SUCCESS = "UPDATE_PROFILE_PIC_SUCCESS";
export const UPDATE_PROFILE_PIC_ERROR = "UPDATE_PROFILE_PIC_ERROR";

export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

export const USER_BY_EMAIL_LOADING = "USER_BY_EMAIL_LOADING";
export const GET_USER_BY_EMAIL_ERROR = "GET_USER_BY_EMAIL_ERROR";
export const GET_USER_BY_EMAIL_SUCCESS = "GET_USER_BY_EMAIL_SUCCESS";

export const USERS_BY_ROLE_LOADING = "USERS_BY_ROLE_LOADING";
export const GET_USERS_BY_ROLE_ERROR = "GET_USERS_BY_ROLE_ERROR";
export const GET_USERS_BY_ROLE_SUCCESS = "GET_USERS_BY_ROLE_SUCCESS";

export const RESEND_LOADING = "RESEND_LOADING";
export const RESEND_SUCCESS = "RESEND_SUCCESS";
export const RESEND_ERROR = "RESEND_ERROR";

export function setUserData(user) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export const signUp = (data) => async (dispatch) => {
  dispatch({
    type: SIGNUP_LOADING,
  });
  try {
    let res = await UserService.create(data);

    return dispatch({
      type: SIGNUP_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: SIGNUP_ERROR,
      payload: err.response,
    });
  }
};

export const resetPwd = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    let res = await axios({
      method: "POST",
      url: `/users/resetpasswordmail`,
      data,
    });
    return dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: RESET_PASSWORD_ERROR,
      payload: err.response,
    });
  }
};

export const getUserById = (id) => async (dispatch) => {
  dispatch({
    type: MANAGER_LOADING,
  });
  try {
    let res = await axios({
      method: "GET",
      url: `/users/${id}`,
    });
    return dispatch({
      type: GET_MANAGER_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: GET_MANAGER_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const updateUser = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_LOADING,
  });
  try {
    let res = await axios({
      method: "PATCH",
      url: `/users/${data.id}`,
      data,
    });
    return dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: UPDATE_USER_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const changePassword = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    let res = await axios({
      method: "PATCH",
      url: `/users/changepassword/${data.id}`,
      data,
    });
    return dispatch({
      type: ADD_USER,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: USER_CREATE_ERROR,
      payload: err.response,
    });
  }
};

export const getUserByResetString = (id) => async (dispatch) => {
  dispatch({
    type: MANAGER_LOADING,
  });
  try {
    let res = await axios({
      method: "GET",
      url: `/users/reset/${id}`,
    });
    return dispatch({
      type: GET_MANAGER_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: GET_MANAGER_ERROR,
      payload: err.response,
    });
  }
};

export const getUserByVerifyString = (id) => async (dispatch) => {
  dispatch({
    type: MANAGER_LOADING,
  });
  try {
    let res = await axios({
      method: "GET",
      url: `/users/verify/${id}`,
    });
    return dispatch({
      type: GET_MANAGER_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: GET_MANAGER_ERROR,
      payload: err.response,
    });
  }
};

export const updateVerifyString = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    let res = await axios({
      method: "PATCH",
      url: `/users/verify/${data.id}`,
      data,
    });
    return dispatch({
      type: ADD_USER,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: USER_CREATE_ERROR,
      payload: err.response,
    });
  }
};

export const createArtist = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    let res = await axios({
      method: "POST",
      url: `/users/artist`,
      data,
    });
    return dispatch({
      type: ADD_USER,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: USER_CREATE_ERROR,
      payload: err.response,
    });
  }
};

export const updateProfilePic = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE_PIC_LOADING,
  });
  try {
    let res = await axios({
      method: "PATCH",
      url: `/users/updateprofilepic/${data.id}`,
      data,
    });
    return dispatch({
      type: UPDATE_PROFILE_PIC_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: UPDATE_PROFILE_PIC_ERROR,
      payload: err.response,
    });
  }
};

export const getUserByEmail = (id) => async (dispatch) => {
  dispatch({
    type: USER_BY_EMAIL_LOADING,
  });
  try {
    let res = await UserService.getUserByEmail(id);
    return dispatch({
      type: GET_USER_BY_EMAIL_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: GET_USER_BY_EMAIL_ERROR,
      payload: err.response,
    });
  }
};

export const getUsersByRoleId = (id) => async (dispatch) => {
  dispatch({
    type: USERS_BY_ROLE_LOADING,
  });
  try {
    let res = await UserService.getUsersByRoleId(id);
    return dispatch({
      type: GET_USERS_BY_ROLE_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: GET_USERS_BY_ROLE_ERROR,
      payload: err.response,
    });
  }
};

export const resetPasswordById = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_LOADING,
  });
  try {
    let res = await axios({
      method: "PATCH",
      url: `/users/reset-password-id/${data.id}`,
      data,
    });
    return dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: UPDATE_USER_ERROR,
      payload: err.response,
    });
  }
};


export const resendVerificationMail = (data) => async (dispatch) => {
  dispatch({
    type: RESEND_LOADING,
  });
  try {
    let res = await UserService.resendVerificationMail(data);
    return dispatch({
      type: RESEND_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: RESEND_ERROR,
      payload: err.response,
    });
  }
};